import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import Box from '@mui/material/Box';

import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import {
  assignCoding,
  createCode,
  deleteCode,
  unassignCoding,
  updateCode,
} from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { Code, Codebook, Entry } from 'src/types/insights';
import CodeChip from '../Common/CodeChip';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import CreateCodeDialog from '../Dialogs/CreateCodeDialog';
import UpdateCodeDialog from '../Dialogs/UpdateCodeDialog';
import useDialogState from '../utils/dialog';
import CodeAccordion from './CodeAccordion';
import CodeAccordionMenu from './CodeAccordionMenu';

interface BaseProps {
  filter: string;
}

interface StateProps {
  codebook: Codebook;
  codes: Code[];
  selectedEntry?: Entry;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  codebook: catalogSelectors.getCodebook(state),
  codes: catalogSelectors.getThematicCodes(state),
  selectedEntry: catalogSelectors.getSelectedEntry(state),
});

//TODO: delete me after insights restyle feature flag
const ThematicCodeAccordion: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { codebook, codes, dispatch, filter, selectedEntry } = props;
  const { t } = useTranslation();

  const [selectedCode, setSelectedCode] = React.useState<Code | undefined>();
  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const openMenu = (code: Code, element: Element) => {
    setSelectedCode(code);
    setMenuAnchor(element);
  };
  const closeCodeMenu = () => {
    setMenuAnchor(null);
  };

  const [dialogState, openDialog, closeDialog] = useDialogState({
    onOpen: closeCodeMenu,
  });

  const selectCode = (id: number, selected: boolean) => {
    if (selectedEntry !== undefined) {
      if (selected) {
        dispatch(assignCoding([id, selectedEntry.id]));
      } else {
        dispatch(unassignCoding([id, selectedEntry.id]));
      }
    }
  };

  return (
    <Box data-testid="thematic-code-accordion">
      <CodeAccordion
        codes={codes}
        filter={filter}
        onClickAdd={openDialog('create')}
        openMenu={openMenu}
        selectCode={selectCode}
        selecting={Boolean(selectedEntry)}
        title={t('insights.thematic_codes')}
      />
      <CreateCodeDialog
        closeDialog={closeDialog('create')}
        codeType={'thematic'}
        codes={codes}
        onSuccess={(code: any) => {
          dispatch(
            createCode({ ...code, codebook_id: codebook.id, parentage: [] })
          );
        }}
        open={Boolean(dialogState['create'])}
      />
      {selectedCode && (
        <>
          <CodeAccordionMenu
            anchor={menuAnchor}
            closeMenu={closeCodeMenu}
            label={t('insights.code')}
            openDialog={openDialog}
            selectedCode={selectedCode}
          />
          <ConfirmationDialog
            closeDialog={closeDialog('delete')}
            description={
              <Trans
                i18nKey="insights.delete_code_confirmation"
                /*eslint-disable */
                components={{ 1: <CodeChip code={selectedCode} /> }}
                /*eslint-disable */
              />
            }
            onSuccess={() => {
              dispatch(deleteCode(selectedCode.id));
            }}
            open={Boolean(dialogState['delete'])}
            submitText={t('common.delete')}
            title={t('insights.delete_code')}
          />
          <UpdateCodeDialog
            closeDialog={closeDialog('update')}
            code={selectedCode}
            codes={codes}
            onSuccess={(code) => {
              dispatch(updateCode(code));
            }}
            open={Boolean(dialogState['update'])}
          />
        </>
      )}
    </Box>
  );
};

ThematicCodeAccordion.displayName = 'ThematicCodeAccordion';

export default connect(mapStateToProps)(ThematicCodeAccordion);
