import * as React from 'react';
import CSVReader from 'react-csv-reader';
import { Trans, useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';

import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import {
  assignDemographic,
  createDemographic,
  deleteDemographic,
  setError,
  unassignDemographic,
  updateDemographic,
  uploadDemographics,
} from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import {
  Catalog,
  Demographic,
  ImportedParticipantRow,
  Participant,
} from 'src/types/insights';
import CodeChip from '../Common/CodeChip';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import CreateCodeDialog from '../Dialogs/CreateCodeDialog';
import ModalDialog from '../Dialogs/ModalDialog';
import UpdateCodeDialog from '../Dialogs/UpdateCodeDialog';
import useDialogState from '../utils/dialog';
import CodeAccordion from './CodeAccordion';
import CodeAccordionMenu from './CodeAccordionMenu';

interface BaseProps {
  filter: string;
}

interface StateProps {
  catalog: Catalog | undefined;
  codes: Demographic[];
  error?: Error;
  selectedParticipant?: Participant;
}

const mapStateToProps = (state: StoreState): StateProps => ({
  catalog: catalogSelectors.getCatalog(state),
  codes: catalogSelectors.getDemographics(state),
  error: catalogSelectors.getError(state),
  selectedParticipant: catalogSelectors.getSelectedParticipant(state),
});

type Props = BaseProps & StateProps & DispatchProp;

//TODO: delete me after insights restyle feature flag
const DemographicAccordion: React.FunctionComponent<Props> = (props: Props) => {
  const { catalog, codes, error, dispatch, filter, selectedParticipant } =
    props;
  const { t } = useTranslation();

  const [selectedDemographic, setSelectedDemographic] = React.useState<
    Demographic | undefined
  >();
  const [menuAnchor, setMenuAnchor] = React.useState<Element | null>(null);
  const openMenu = (demographic: Demographic, element: Element) => {
    setSelectedDemographic(demographic);
    setMenuAnchor(element);
  };
  const closeCodeMenu = () => {
    setMenuAnchor(null);
  };

  const [dialogState, openDialog, closeDialog] = useDialogState({
    onOpen: closeCodeMenu,
  });

  const handleBulkUpload = (data: string[][]) => {
    // separate demograhics from conversationID and participant name
    const demographicNames: string[] = data[0].slice(2);
    const uploadedRows: ImportedParticipantRow[] = [];
    // remove header
    data.slice(1).forEach((row: string[]) => {
      const conversationID: number = parseInt(row[0]);
      const speakerName: string = row[1];
      const speakerDemographics: string[] = row.slice(2);
      demographicNames.forEach((demographic: string, index: number) => {
        if (
          speakerDemographics[index] !== '' &&
          speakerDemographics[index] !== 'n/a' &&
          // skip duplicates
          !uploadedRows.find((row: ImportedParticipantRow) => {
            return (
              row.conversation_id === conversationID &&
              row.participant_name === speakerName &&
              row.demographic_name === speakerDemographics[index]
            );
          })
        ) {
          uploadedRows.push({
            participant_name: speakerName,
            conversation_id: conversationID,
            demographic_name: speakerDemographics[index],
            parent_name: demographic,
          });
        }
      });
    });
    dispatch(uploadDemographics([catalog!.id, uploadedRows]));
  };

  const selectDemographic = (id: number, selected: boolean) => {
    if (selectedParticipant !== undefined) {
      if (selected) {
        dispatch(assignDemographic([id, selectedParticipant.id]));
      } else {
        dispatch(unassignDemographic([id, selectedParticipant.id]));
      }
    }
  };

  return (
    <Box id="demographic-accordion">
      <CodeAccordion
        additionalIconButtons={
          <BulkUploadButton openDialog={openDialog('bulkUploadInfo')} />
        }
        codes={codes}
        filter={filter}
        onClickAdd={openDialog('create')}
        openMenu={openMenu}
        selectCode={selectDemographic}
        selectedCodes={
          selectedParticipant !== undefined
            ? selectedParticipant.demographic_ids
            : []
        }
        selecting={Boolean(selectedParticipant)}
        title={t('insights.demographic_codes')}
      />
      <CreateCodeDialog
        closeDialog={closeDialog('create')}
        codeType={'demographic'}
        codes={codes}
        onSuccess={(code) => {
          catalog &&
            dispatch(
              createDemographic({
                ...code,
                catalog_id: catalog.id,
                parentage: [],
              })
            );
        }}
        open={Boolean(dialogState['create'])}
      />
      {selectedDemographic !== undefined && (
        <>
          <CodeAccordionMenu
            anchor={menuAnchor}
            closeMenu={closeCodeMenu}
            label={t('insights.demographic')}
            openDialog={openDialog}
            selectedCode={selectedDemographic}
          />
          <ConfirmationDialog
            closeDialog={closeDialog('delete')}
            description={
              <Trans
                i18nKey="insights.delete_code_confirmation"
                /*eslint-disable */
                components={{ 1: <CodeChip code={selectedDemographic} /> }}
                /*eslint-disable */
              />
            }
            onSuccess={() => {
              dispatch(deleteDemographic(selectedDemographic.id));
            }}
            open={Boolean(dialogState['delete'])}
            submitText={t('common.delete')}
            title={t('insights.delete_code')}
          />
          <UpdateCodeDialog<Demographic>
            closeDialog={closeDialog('update')}
            code={selectedDemographic}
            codes={codes}
            onSuccess={(code) => {
              dispatch(updateDemographic(code));
            }}
            open={Boolean(dialogState['update'])}
          />
        </>
      )}
      <CSVReader
        inputStyle={{ display: 'none' }}
        onFileLoaded={handleBulkUpload}
        parserOptions={{ skipEmptyLines: true }}
      />
      <ModalDialog
        maxWidth="xs"
        onClose={closeDialog('bulkUploadInfo')}
        open={Boolean(dialogState['bulkUploadInfo'])}
      >
        <>
          <DialogTitle>{t('insights.upload_bulk_demographic')}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <DialogContentText>
              {t('insights.upload_demographic_header')}
              <ul>
                <li>
                  {t('insights.upload_demographic_first_row')}
                  <ul>
                    <li>{t('insights.upload_demographic_first_column')}</li>
                    <li>{t('insights.upload_demographic_second_column')}</li>
                    <li>{t('insights.upload_demographic_third_column')}</li>
                  </ul>
                </li>
                <li>{t('insights.upload_demographic_second_row')}</li>
              </ul>
              {t('insights.upload_demographic_footer')}
            </DialogContentText>
          </DialogContent>
        </>
      </ModalDialog>
      {error && error.name === 'Unmatched Rows' && (
        <ModalDialog maxWidth="sm" onClose={() => dispatch(setError())} open>
          <>
            <DialogTitle>{t('insights.upload_demographic_fail')}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <DialogContentText>
                {t('insights.upload_demographic_fail_description')}
              </DialogContentText>
              {error && (
                <TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('insights.conversation_id')}</TableCell>
                        <TableCell>{t('common.name')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {error.message
                        .match(/'\w+', \d+/g)!
                        .map((row: string, index: number) => {
                          const splitRow: string[] = row.split(',');
                          return (
                            <TableRow key={`${splitRow[0]}-${index}`}>
                              <TableCell>
                                {parseInt(splitRow[1].trim())}
                              </TableCell>
                              <TableCell>{splitRow[0].slice(1, -1)}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DialogContent>
          </>
        </ModalDialog>
      )}
    </Box>
  );
};

const BulkUploadButton = ({ openDialog }: { openDialog: () => void }) => {
  const { t } = useTranslation();
  const handleBulkUploadClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('react-csv-reader-input')?.click();
  };

  const handleBulkUploadInfoClicked = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    openDialog();
  };
  return (
    <Tooltip
      arrow
      placement="left"
      title={
        <>
          <Typography variant="caption">
            {t('insights.upload_demographics')}
          </Typography>
          <IconButton onClick={handleBulkUploadInfoClicked} sx={{ py: '4px' }}>
            <HelpOutlineIcon color="secondary" fontSize="small" />
          </IconButton>
        </>
      }
    >
      <IconButton onClick={handleBulkUploadClicked} sx={{ py: '4px' }}>
        <UploadFileIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

DemographicAccordion.displayName = 'DemographicAccordion';

export default connect(mapStateToProps)(DemographicAccordion);
