import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment-timezone';

import Badge from 'src/components/Badge/Badge';
import Button from 'src/components/core/Button/Button';
import TagList from 'src/components/Highlight/TagList/TagList';
import HighlightCrossPollinationDropdown from 'src/components/HighlightCrossPollinationDropdown/HighlightCrossPollinationDropdown';
import HighlightDropdown from 'src/components/HighlightDropdown/HighlightDropdown';
import HighlightFooter from 'src/components/HighlightFooter/HighlightFooter';
import HighlightModal from 'src/components/HighlightModal/HighlightModal';
import HighlightStarButton from 'src/components/HighlightStarButton/HighlightStarButton';
import HighlightTypeDropdown from 'src/components/HighlightTypeDropdown/HighlightTypeDropdown';
import HighlightTypeLabel from 'src/components/HighlightTypeLabel/HighlightTypeLabel';
import Panel from 'src/components/Panel/Panel';
import PanelActions from 'src/components/Panel/PanelActions/PanelActions';
import PlayButton from 'src/components/PlayButton/PlayButton';
import Restrict from 'src/components/Restrict/Restrict';
import ScrollingTranscript from 'src/components/ScrollingTranscript/ScrollingTranscript';
import ShareControl from 'src/components/ShareControl/ShareControl';
import TranscriptRoll from 'src/components/TranscriptRoll/TranscriptRoll';
import GlobalAudioContext, {
  useSeekAudio,
} from 'src/contexts/GlobalAudioContext';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import authSelectors from 'src/redux/auth/auth-selectors';
import highlightSelectors from 'src/redux/highlight/highlight-selectors';
import { editHighlight } from 'src/redux/highlight/highlight-slice';
import { StoreState } from 'src/redux/store';
import { Action, Category, Name } from 'src/types/analytics';
import { User } from 'src/types/auth';
import {
  AnnotationPrivacyLevel,
  Conversation,
  Highlight,
  Host,
  Snippet,
} from 'src/types/conversation';
import {
  isActiveAudio,
  mapSeekTimeToConversation,
  mapSeekTimeToHighlight,
} from 'src/util/audio';
import dateFormatter from 'src/util/date';
import { formatTime } from 'src/util/format';
import { snippetsToUniqueSpeakers } from 'src/util/snippets';
import {
  getHighlightPermission,
  getHighlightPrivacyPermission,
  getStaffFeaturesPermission,
  hasOrganization,
  hasPermission,
} from 'src/util/user';
import DownloadButton from './DownloadButton/DownloadButton';
import HighlightTitle from './HighlightTitle/HighlightTitle';

import styles from './HighlightCard.module.scss';

interface BaseProps {
  highlight: Highlight;
  /** if provided, use this snippets list for the transcript, otherwise highlight.snippets */
  snippets?: Snippet[] | undefined;
  /** if provided, use this conversation, otherwise highlight.conversation */
  conversation?: Conversation | undefined;
  className?: string | undefined;

  /** True if shown in a conversation details page, so we don't link to the conversation */
  inConversation?: boolean;

  /** In conversation details, we can navigate directly to the seek time with this. */
  onViewInTranscript?: () => void;

  /** True if the card is rendered on an individual highlight page */
  onHighlightPage?: boolean;

  /** can emphasize caption and transcript */
  emphasizedTerms?: string[];
  onDelete?: () => void;
}

interface StateProps {
  user: User;
  isSaving: boolean;
  isSaved: boolean;
  saveError: Error | undefined;
}

type Props = BaseProps & StateProps & DispatchProp;

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState, props: BaseProps): StateProps => {
  const { highlight } = props;
  const editHighlightId = highlightSelectors.getEditHighlightId(state);

  return {
    user: authSelectors.getUser(state),

    isSaving:
      highlight.id === editHighlightId &&
      highlightSelectors.isSavingEditHighlight(state),
    isSaved:
      highlight.id === editHighlightId &&
      highlightSelectors.isSavedEditHighlight(state),
    saveError:
      highlight.id === editHighlightId
        ? highlightSelectors.getErrorEditHighlight(state)
        : undefined,
  };
};

export const HighlightCard = ({
  highlight,
  snippets,
  conversation,
  className,
  user,
  dispatch,
  inConversation,
  onViewInTranscript,
  onHighlightPage,
  emphasizedTerms,
  onDelete,
}: Props) => {
  const { t } = useTranslation();
  const {
    audio_start_offset,
    audio_end_offset,
    audio_fade_in_out = 1,
  } = highlight;
  if (!snippets) {
    snippets = highlight.snippets || [];
  }

  const { play, changeSound, src, isLoading, audioError, isPlaying } =
    React.useContext(GlobalAudioContext);
  if (!conversation) {
    conversation = highlight.conversation;
  }

  if (conversation == null) {
    const partialConversation: any = {
      title: t('common.loading'),
      time: moment(),
      id: '',
      host: {} as Host,
      audio_url: '',
    };
    conversation = partialConversation as Conversation;
  }

  const { audio_url: conversation_audio_url } = conversation;
  const audio_url = inConversation
    ? conversation_audio_url
    : highlight.audio_url;

  const adjustedAudioStartTime = inConversation ? audio_start_offset : 0;
  const adjustedAudioEndTime = inConversation
    ? audio_end_offset
    : audio_end_offset - audio_start_offset + 2 * audio_fade_in_out;

  const seekAudioData = useSeekAudio({
    audioUrl: audio_url,
    startTime: adjustedAudioStartTime,
    endTime: adjustedAudioEndTime,
    meta: conversation,
    id: `highlight-${highlight.id}`,
  });
  const { isActive, activatedPlay, toggleActivatedPlaying, setIsActive } =
    seekAudioData;
  let { seekTime } = seekAudioData;

  if (!inConversation && isActiveAudio(src, audio_url)) {
    // this is the seek time relative to a conversation audio
    const conversationSeekTime = mapSeekTimeToConversation(
      seekTime,
      audio_start_offset,
      audio_fade_in_out
    );
    seekTime = conversationSeekTime;
  }

  const handleChangeHighlightPrivacy = (privacy: AnnotationPrivacyLevel) =>
    dispatch(
      editHighlight({
        highlight,
        changes: {
          privacy_level: privacy,
        },
      })
    );

  const isFeatured = highlight.annotation_type === 'highlight_curated';
  const handleToggleFeatured = () =>
    dispatch(
      editHighlight({
        highlight,
        changes: {
          annotation_type: !isFeatured // negate since we are switching
            ? 'highlight_curated'
            : 'highlight_community',
        },
      })
    );

  /** Modal state and callbacks */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState<string | null>(null);
  const handleCloseModal = React.useCallback(() => {
    setModalIsOpen(false);
    setModalType(null);
  }, []);
  const handleOpenModal = (type: string) => {
    setModalType(type);
    setModalIsOpen(true);
  };

  // useCallback needed so we don't constantly create new functions causing
  // scrolling transcript to re-render all the time
  const handlePlay = React.useCallback(
    (seekTime?: number) => {
      const adjustedSeekTime = inConversation
        ? seekTime
        : mapSeekTimeToHighlight(
            seekTime,
            audio_start_offset,
            audio_fade_in_out
          );

      activatedPlay(
        seekTime == null ? audio_start_offset : adjustedSeekTime,
        adjustedAudioEndTime
      );
    },
    [
      inConversation,
      audio_start_offset,
      audio_fade_in_out,
      activatedPlay,
      adjustedAudioEndTime,
    ]
  );

  const handleSeek = React.useCallback(
    (seekTime: number) => {
      const adjustedSeekTime = inConversation
        ? seekTime
        : mapSeekTimeToHighlight(
            seekTime,
            audio_start_offset,
            audio_fade_in_out
          );

      changeSound(audio_url, conversation);
      play(adjustedSeekTime, adjustedAudioEndTime);
      setIsActive(true);
    },
    [
      inConversation,
      audio_start_offset,
      audio_fade_in_out,
      changeSound,
      audio_url,
      conversation,
      play,
      adjustedAudioEndTime,
      setIsActive,
    ]
  );

  // toggling transcript style between scrolling window and roll
  const [transcriptStyle, setTranscriptStyle] = React.useState('full');
  const handleToggleTranscriptStyle = () => {
    if (transcriptStyle === 'roll') {
      setTranscriptStyle('full');
    } else {
      setTranscriptStyle('roll');
    }
  };

  const hasTags = highlight.tags && highlight.tags.length > 0;

  const { analyticsEvent } = useAnalyticsContext();
  const handleDownload = (type: string) => {
    analyticsEvent({
      category: Category.Highlight,
      action: Action.Download,
      name: Name.HighlightCard,
    });

    // do not show license modal if user is in the org that owns the conversation the highlight is from
    if (
      !conversation ||
      !hasOrganization(user, conversation.collection.organization_id)
    ) {
      handleOpenModal(type);
    }
  };

  // if user can publicize highlight, then can download it
  const showDownloadButton = hasPermission(
    user,
    getHighlightPrivacyPermission('update', conversation.collection.id)
  );
  const downloadFilename = `Cortico ${
    conversation.title
  } - ${snippetsToUniqueSpeakers(highlight.snippets || []).join(', ')}.mp3`;

  return (
    <>
      <HighlightModal
        conversation={conversation}
        highlight={highlight}
        modalType={modalType}
        snippets={snippets}
        handleCloseModal={handleCloseModal}
        modalIsOpen={modalIsOpen}
        onDelete={onDelete}
      />
      <Panel
        data-testid="highlight-card"
        className={cx(
          className,
          'pt-3 px-3 pb-0',
          styles[`panelHighlightType_${highlight.annotation_type}`],
          {
            [styles.panelPrivateHighlight]:
              highlight.privacy_level === 'private',
          }
        )}
        p={null}
      >
        <div className="mb-3">
          <div className={cx('float-end', styles.topIcons)}>
            <Badge pill color="gray" className="me-2" pale>
              {formatTime(
                highlight.audio_end_offset - highlight.audio_start_offset
              )}
            </Badge>
            {highlight.number_of_cross_pollinations && (
              <HighlightCrossPollinationDropdown
                numberOfCrossPollinations={
                  highlight.number_of_cross_pollinations
                }
                crossPollinatedConversations={
                  highlight.cross_pollinated_conversations
                    ? highlight.cross_pollinated_conversations
                    : []
                }
                className="d-inline-block"
              />
            )}
            {showDownloadButton && (
              <DownloadButton
                downloadHref={highlight.audio_url}
                onDownload={handleDownload}
                filename={downloadFilename}
              />
            )}
            <HighlightStarButton highlight={highlight} dispatch={dispatch} />
            <HighlightDropdown
              conversation={conversation}
              highlight={highlight}
              handleToggleFeatured={handleToggleFeatured}
              handleOpenModal={handleOpenModal}
              isFeatured={isFeatured}
              user={user}
              noHighlightLink={onHighlightPage}
            />
          </div>
          <h3 className="mb-0 h5">
            <Link
              to={`/highlight/${highlight.id}`}
              data-testid="highlight-link"
            >
              <HighlightTitle highlight={highlight} snippets={snippets} />
            </Link>
          </h3>
          <div>
            {!hasPermission(
              user,
              getHighlightPermission('create', conversation.collection.id)
            ) ? (
              <HighlightTypeLabel
                highlight={highlight}
                conversation={conversation}
                className="fwbold small-header text-muted"
              />
            ) : (
              <HighlightTypeDropdown
                highlight={highlight}
                collection={conversation.collection}
                conversation={conversation}
                onChange={handleChangeHighlightPrivacy}
                user={user}
                className="ms-n2" // to make up for the padding of the button
              />
            )}
          </div>
        </div>
        {/*<button onClick={handleOpenModal}>Open Modal</button> */}

        <div className={cx(styles.description, 'mb-3')}>
          <div className="fwbold">
            {highlight.user_name}{' '}
            <span className="text-muted ms-1 fwnormal small">
              {dateFormatter.relativeAbsDateFormat(highlight.created_at)}
            </span>
          </div>
          {emphasizedTerms == null ? (
            highlight.description
          ) : (
            <Highlighter
              highlightClassName={styles.emphasizedTerm}
              searchWords={emphasizedTerms}
              autoEscape={true}
              textToHighlight={highlight.description}
            />
          )}
        </div>

        <div className="clearfix">
          <div className={hasTags ? 'mb-2' : 'mb-3'}>
            {transcriptStyle === 'roll' ? (
              <TranscriptRoll
                seekTime={seekTime}
                snippets={snippets}
                startTime={audio_start_offset}
                endTime={audio_end_offset}
                onSeek={handleSeek}
                audioIsLoaded={isActiveAudio(src, audio_url) && !isLoading}
                withDurationBar
              />
            ) : (
              <ScrollingTranscript
                conversation={conversation}
                seekTime={seekTime}
                onPlay={handlePlay}
                snippets={snippets}
                filterWordsStart={audio_start_offset}
                filterWordsEnd={audio_end_offset}
                emphasizedTerms={emphasizedTerms}
              />
            )}

            <Restrict permission={getStaffFeaturesPermission('read')}>
              <Button
                shape="circle"
                icon={
                  transcriptStyle === 'roll'
                    ? ['fas', 'align-left']
                    : ['fas', 'comment-dots']
                }
                onClick={handleToggleTranscriptStyle}
                className={cx(styles.transcriptToggler, 'float-end mt-2')}
                title={t('highlight.change_transcript_view_mode')}
                data-testid="transcript-toggler"
              />
            </Restrict>
            {hasTags ? (
              <div className="mt-1">
                <TagList tags={highlight.tags} />
              </div>
            ) : null}
          </div>

          {!inConversation && (
            <HighlightFooter
              conversation={conversation}
              linkToConversation={highlight.can_access_conversation}
            />
          )}
        </div>
        <PanelActions>
          <div>
            <PlayButton
              isPlaying={isActive && isPlaying}
              isLoading={isActive && isLoading}
              isError={isActive && audioError != null}
              onClick={toggleActivatedPlaying}
              className="me-1 highlight-card-play-button"
            />
          </div>
          <div>
            <ShareControl
              highlight={highlight}
              conversation={conversation}
              user={user}
              onChange={handleChangeHighlightPrivacy}
              className="me-1 d-inline-block"
            />
            {inConversation ? (
              <Button onClick={onViewInTranscript}>
                {t('conversation.transcript_view')}
              </Button>
            ) : (
              highlight.can_access_conversation && (
                <Button
                  tag={Link}
                  {...{
                    to: `/conversation/${conversation.id}/?t=${highlight.audio_start_offset}`,
                  }}
                  color="default"
                  data-testid="highlight-to-conversation-btn"
                >
                  {t('conversations.go_to')}
                </Button>
              )
            )}
          </div>
        </PanelActions>
      </Panel>
    </>
  );
};

export default connect(mapStateToProps)(React.memo(HighlightCard));
