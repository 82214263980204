import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

export default function ({
  labelKey,
  onChange,
  initialValue = '',
  ...textFieldProps
}: Omit<TextFieldProps, 'onChange'> & {
  labelKey: string;
  initialValue?: string;
  onChange: (value: string) => void;
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  useDebounce(() => onChange(value), 500, [value]);

  return (
    <TextField
      InputProps={{
        endAdornment: value?.length !== 0 && (
          <InputAdornment position="end">
            <Tooltip arrow title={t('insights.clear_filters')}>
              <IconButton
                edge="end"
                onClick={() => setValue('')}
                onMouseDown={(e) => e.preventDefault()}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        sx: {
          borderRadius: '20px',
          pr: '20px',
          pl: '6px',
        },
      }}
      InputLabelProps={{
        sx: {
          pl: '6px',
          fontSize: '14px',
        },
      }}
      data-testid="insights_search"
      fullWidth
      label={t(labelKey)}
      margin="normal"
      onChange={(e) => setValue(e.target.value)}
      onKeyUp={(event) => {
        if (event.key === 'Escape') {
          setValue('');
        }
      }}
      size="small"
      value={value}
      variant="outlined"
      {...textFieldProps}
    />
  );
}
