import { useEffect } from 'react';

import { VStack } from 'src/components/core/Stack';
import { HighlightCardModal } from 'src/components/Insights/Catalog/HighlightCardModal';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import { HighlightsPageToolbar } from 'src/components/Insights/Catalog/HighlightsPageToolbar';
import { selectEntry } from 'src/redux/catalog/catalog-slice';
import FilterableHighlightGrid from '../Filters/FilterableHighlightGrid';

export default function HighlightsPage() {
  const { selectedEntry, allEntries, showModal, hideModal, dispatch } =
    useHighlightsPageContext();

  useEffect(() => {
    if (!selectedEntry) return;

    hideModal(); // hides previous highlight modal when using carousel nav
    showModal(<HighlightCardModal />, {
      onClose: () => dispatch(selectEntry()),
      withCodebook: true,
      containerStyle: {
        background: 'transparent',
        maxHeight: 'unset',
        height: '100%',
        pointerEvents: 'none',
        width: '70%',
      },
    });
  }, [selectedEntry]);

  return (
    <VStack margin="0 auto" flexGrow={1}>
      <VStack
        maxWidth={'1280px'}
        width={'100%'}
        alignSelf={'center'}
        padding={'0 !important'}
      >
        <HighlightsPageToolbar />
      </VStack>
      <FilterableHighlightGrid />
    </VStack>
  );
}
