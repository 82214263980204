import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { AbstractCode } from 'src/types/insights';
import CodeChip from '../Common/CodeChip';

interface BaseProps {
  code?: AbstractCode;
  codes: AbstractCode[];
  disabledCodes?: AbstractCode['id'][];
  onChange: (parentId: number) => void;
}

type Props = BaseProps;

const CodeSelect: React.FunctionComponent<Props> = (props: Props) => {
  const { code, codes, disabledCodes, onChange } = props;
  const { t } = useTranslation();
  const [codeId, setCodeId] = React.useState(
    `${
      code !== undefined && code.parent_id !== undefined ? code.parent_id : -1
    }`
  );
  const handleChange = (event: SelectChangeEvent) => {
    setCodeId(event.target.value as string);
    onChange(Number(event.target.value));
  };
  return (
    <Box sx={{ minWidth: 360 }}>
      <FormControl fullWidth>
        <InputLabel id="code-select">{t('insights.code_parent')}</InputLabel>
        <Select
          label={t('insights.code_parent')}
          labelId="code-select"
          onChange={handleChange}
          value={codeId}
        >
          <MenuItem key={-1} value="-1">
            <CodeChip
              code={{
                name: t('insights.no_parent'),
                id: -1,
                color: '#ffffff',
                parentage: [-1],
                code_type: 'none',
              }}
            />
          </MenuItem>
          {codes.map((currentCode) => (
            <MenuItem
              disabled={
                disabledCodes !== undefined &&
                disabledCodes.indexOf(currentCode.id) !== -1
              }
              key={currentCode.id}
              sx={{ pl: 2 + 4 * (currentCode.parentage || []).length }}
              value={currentCode.id}
            >
              <CodeChip code={currentCode} />
              {code !== undefined && currentCode.id === code.id && <StarIcon />}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CodeSelect.displayName = 'CodeSelect';

export default CodeSelect;
