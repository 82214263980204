import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useTabNavigation } from 'src/components/Insights/Catalog/NavigationTabs';
import { ModalDialogProps } from 'src/components/Insights/Dialogs/ModalDialog';
import authSelectors from 'src/redux/auth/auth-selectors';
import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import { loadCatalog } from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { createProvider } from 'src/util/provider';
import { useHashHandler } from 'src/util/useHashHandler';

export const [CatalogPageProvider, useCatalogPageContext] = createProvider(
  () => {
    const dispatch = useDispatch();
    const catalog = useSelector(catalogSelectors.getCatalog);
    const conversations = useSelector(catalogSelectors.getConversations);
    const allEntries = useSelector(catalogSelectors.getAllEntries);
    const isLoading = useSelector(catalogSelectors.isLoading);
    const organizationIds = useSelector((state: StoreState) =>
      authSelectors
        .getOrganizations(state)
        .map((organization) => organization.id)
    );
    const participants = useSelector(catalogSelectors.getParticipants);
    const selectedConversation = useSelector(
      catalogSelectors.getSelectedConversation
    );
    const selectedEntry = useSelector(catalogSelectors.getSelectedEntry);
    const iAmAdminOrOwner = useSelector(catalogSelectors.iAmAdminOrOwner);
    const sensemakers = useSelector(catalogSelectors.getSensemakers);

    // ••• INIT

    const match = useRouteMatch<{ catalogId: string }>();
    const catalogId = +match.params.catalogId;

    useEffect(() => {
      dispatch(loadCatalog(catalogId));
    }, [catalogId, dispatch]);

    // ••• MODAL

    const [modals, setModals] = useState<ModalDialogProps[]>([]);

    const showModal = (
      component: ReactElement,
      props: Partial<ModalDialogProps & { withCodebook?: boolean }> = {}
    ) => {
      const { sx = {}, withCodebook, onClose } = props;
      const _props = {
        open: true,
        ...props,
        sx: {
          ...(withCodebook
            ? { marginTop: '72px' } // space for title bar
            : { width: '100%' }),
          ...sx,
        },
        onClose: () => {
          hideModal();
          onClose?.();
        },
        children: component,
      };
      setModals((prev) => [...prev, _props]);
    };

    const hideModal = () => setModals((prev) => prev.slice(0, -1));

    // ••• NAVIGATION

    const { activeTab, setActiveTab } = useTabNavigation();
    const showingOverview = activeTab === Tab.Overview;
    const showingConversations = activeTab === Tab.Conversations;
    const showingHighlights = activeTab === Tab.Highlights;

    useEffect(() => {
      if (
        catalog.organization_id !== -1 &&
        organizationIds.indexOf(catalog.organization_id) === -1
      ) {
        setActiveTab(-1); // -1 triggers NotFoundPage
      }
    }, [catalog, organizationIds]);

    useHashHandler(isLoading);

    // ••• CONTEXT

    return {
      catalog,
      conversations,
      allEntries,
      isLoading,
      organizationIds,
      participants,
      selectedConversation,
      selectedEntry,
      iAmAdminOrOwner,
      sensemakers,
      dispatch,
      t: useTranslation().t,

      activeTab,
      setActiveTab,
      showingOverview,
      showingConversations,
      showingHighlights,
      hasCodebook: showingConversations || showingHighlights,

      modals,
      showModal,
      hideModal,
    };
  }
);

// DEFINITIONS

export const Tab = {
  Overview: 0,
  Conversations: 1,
  Highlights: 2,
  Sensemakers: 3,
};
