import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { useCodebookContext } from 'src/components/Insights/Catalog/CodebookProvider';
import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import {
  selectConversation,
  selectParticipant,
} from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { Conversation } from 'src/types/conversation';
import { createProvider } from 'src/util/provider';

export const [ConversationsPageProvider, useConversationsPageContext] =
  createProvider(() => {
    const catalogPageContext = useCatalogPageContext();
    const { allEntries, conversations, dispatch, selectedConversation, t } =
      catalogPageContext;

    // Filters

    const [filters, setFilters] = useState<Set<Conversation>>(new Set());

    const filteredConversations = useMemo(() => {
      const filteredConvos = conversations.filter((c) => filters.has(c));
      return filters.size ? filteredConvos : conversations;
    }, [conversations, filters]);

    // FE pagination - currently not true pagination, but kept as an aesthetic choice.
    const [currentPage, setCurrentPage] = useState(1);
    const pages = useMemo(
      () => _.chunk(filteredConversations, CONVERSATIONS_PER_PAGE),
      [filteredConversations]
    );
    const visibleConversations = useMemo(
      () => pages[currentPage - 1],
      [pages, currentPage]
    );

    // Conversation Selection
    const onConversationClick = (id: number) =>
      dispatch(selectConversation(id));

    const onConversationModalClose = () => {
      // Resest selections
      dispatch(selectConversation());
      dispatch(selectParticipant());
    };

    // Conversation Participants Information
    const conversationParticipants = useSelector((state: StoreState) =>
      catalogSelectors.getParticipantsForConversation(
        state,
        selectedConversation ?? ({} as Conversation)
      )
    );
    const participantDemographics = useSelector((state: StoreState) =>
      catalogSelectors.getDemographicsForParticipants(
        state,
        conversationParticipants
      )
    );
    const selectedParticipant = useSelector(
      catalogSelectors.getSelectedParticipant
    );

    // Conversation Participant Selection
    const onSelectConversationParticipant = (id: number) => {
      dispatch(selectParticipant(id));
    };

    // ••• CONTEXT

    return {
      ...catalogPageContext,
      ...useCodebookContext(),

      filters,
      setFilters,

      currentPage,
      setCurrentPage,

      visibleConversations,
      filteredConversations,
      allHighlights: allEntries,

      selectedConversation,
      conversationParticipants,
      participantDemographics,
      selectedParticipant,

      onConversationClick,
      onConversationModalClose,

      onSelectConversationParticipant,
    };
  });

// DEFINITIONS

export const CONVERSATIONS_PER_PAGE = 20;
