import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { CloseOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

import color from 'src/assets/_util.scss';
import { View } from 'src/components/core/View';
import { useCodebookContext } from 'src/components/Insights/Catalog/CodebookProvider';
import { Button } from 'src/components/Insights/Common/Button';
import {
  createCode,
  createDemographic,
  deleteCode,
  deleteDemographic,
  updateCode,
  updateDemographic,
} from 'src/redux/catalog/catalog-slice';
import { AbsoluteFill } from 'src/util/styles';
import { Code, Demographic } from '../../../types/insights';
import { HStack, VStack } from '../../core/Stack';
import CodeChip from '../Common/CodeChip';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';
import CreateCodeDialog from '../Dialogs/CreateCodeDialog';
import UpdateCodeDialog from '../Dialogs/UpdateCodeDialog';
import CodeAccordionMenuModern from '../Sidebar/CodeAccordionMenuModern';
import DemographicAccordionModern from '../Sidebar/DemographicAccordionModern';
import InternalCodeAccordionModern from '../Sidebar/InternalCodeAccordionModern';
import SearchField from '../Sidebar/SearchField';
import StructuralCodeAccordionModern from '../Sidebar/StructuralCodeAccordionModern';
import ThematicCodeAccordionModern from '../Sidebar/ThematicCodeAccordionModern';

export function CodebookModern() {
  const {
    catalog,
    showingHighlights,
    hasCodebook,
    demographicCodes,
    structuralCodes,
    thematicCodes,
    internalCodes,
    codebook,
    selectedCode,
    accordionType,
    setCodeFilter,
    openDialog,
    closeDialog,
    closeCodeMenu,
    menuAnchor,
    dialogState,
    aiReasoningTag,
    dispatch,
    t,
  } = useCodebookContext();

  const codesToUse = {
    demographic: demographicCodes,
    structural: structuralCodes,
    thematic: thematicCodes,
    internal: internalCodes,
  }[accordionType];

  return (
    <View style={{ position: 'relative' }}>
      <VStack
        sx={{
          overflowY: aiReasoningTag ? 'hidden' : 'auto',
          padding: '20px 24px',
          backgroundColor: '#ffffff',
          borderLeft: `1px solid ${color.gray400}`,
          flex: '0 0 auto',
          height: '100%',
        }}
      >
        <CodebookTitle />
        <SearchField
          labelKey="insights.search_codes"
          onChange={setCodeFilter}
          sx={{ marginBottom: '16px' }}
        />
        <ApplySuggestedCodesButton />

        {showingHighlights && (
          <>
            <ThematicCodeAccordionModern />
            <StructuralCodeAccordionModern />
            <InternalCodeAccordionModern />
          </>
        )}
        {hasCodebook && <DemographicAccordionModern />}
      </VStack>
      <AIReasoningDialog />

      {selectedCode && (
        <>
          <CodeAccordionMenuModern
            anchor={menuAnchor}
            closeMenu={closeCodeMenu}
            label={t(
              `insights.${
                accordionType === 'demographic' ? 'demographic' : 'code'
              }`
            )}
            openDialog={openDialog}
          />
          <ConfirmationDialog
            closeDialog={closeDialog('delete')}
            description={
              <Trans
                i18nKey="insights.delete_code_confirmation"
                /*eslint-disable */
                components={{ 1: <CodeChip code={selectedCode} /> }}
                /*eslint-disable */
              />
            }
            onSuccess={() => {
              dispatch(
                accordionType === 'demographic'
                  ? deleteDemographic(selectedCode.id)
                  : deleteCode(selectedCode.id)
              );
            }}
            open={Boolean(dialogState['delete'])}
            submitText={t('common.delete')}
            title={t('insights.delete_code')}
          />
          <UpdateCodeDialog
            closeDialog={closeDialog('update')}
            code={selectedCode}
            codes={codesToUse}
            onSuccess={(code) => {
              dispatch(
                accordionType === 'demographic'
                  ? updateDemographic(code as Demographic)
                  : updateCode(code as Code)
              );
            }}
            open={Boolean(dialogState['update'])}
          />
        </>
      )}
      <CreateCodeDialog
        closeDialog={closeDialog('create')}
        codeType={accordionType}
        codes={codesToUse}
        onSuccess={(code) => {
          dispatch(
            accordionType === 'demographic'
              ? createDemographic({
                  ...code,
                  catalog_id: catalog.id,
                  parentage: [],
                })
              : createCode({ ...code, codebook_id: codebook.id, parentage: [] })
          );
        }}
        open={Boolean(dialogState['create'])}
      />
    </View>
  );
}

function CodebookTitle() {
  const { t } = useCodebookContext();
  return (
    <HStack sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ color: color.foraPurpleDarker }}>
        {t(`insights.${'codebook'}`)}
      </Typography>
    </HStack>
  );
}

function ApplySuggestedCodesButton() {
  const { selectedEntry, applyCode, aiOn } = useCodebookContext();
  const unappliedSuggestedCodings =
    selectedEntry?.merged_codings.filter(
      (code) => code.is_suggested && !code.is_applied
    ) ?? [];
  const [busy, setBusy] = useState(false);

  const onClick = () => {
    if (busy) return;
    setBusy(true);
    unappliedSuggestedCodings.forEach((c) => applyCode(c.code_id));
  };

  useEffect(() => {
    if (busy && !unappliedSuggestedCodings.length) {
      setBusy(false);
    }
  }, [unappliedSuggestedCodings, busy]);

  // Button should show if there are any suggested codes (even if all are applied upon opening entry)
  const showButton =
    aiOn &&
    !!(
      selectedEntry?.merged_codings.filter((code) => code.is_suggested)
        .length ?? 0
    );

  return !showButton ? null : (
    <Button
      sx={{ margin: '8px 0' }}
      tKey={
        busy
          ? 'insights.applyingSuggestedCodes'
          : !unappliedSuggestedCodings.length
          ? 'insights.allSuggestedCodesApplied'
          : 'insights.applySuggestedCodesCount'
      }
      tOptions={{ count: unappliedSuggestedCodings.length }}
      onClick={onClick}
      disabled={!unappliedSuggestedCodings.length}
      busy={busy}
    />
  );
}

function AIReasoningDialog() {
  const { aiReasoningTag, hideAIReasoning, t } = useCodebookContext();

  return !aiReasoningTag ? null : (
    <VStack
      style={{
        ...AbsoluteFill,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px',
      }}
    >
      <View
        style={{
          ...AbsoluteFill,
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(0,0,0,.54)',
        }}
        onClick={hideAIReasoning}
      />
      <VStack
        style={{
          background: 'white',
          borderRadius: '24px',
          padding: '32px 36px',
          position: 'relative',
          maxHeight: '100%',
        }}
      >
        <Typography
          variant="h5"
          style={{
            color: color.foraPurpleDarker,
            fontWeight: 600,
            margin: '0 0 8px',
          }}
        >
          {t('insights.aiReasoning')}
        </Typography>

        <View>
          <CodeChip code={aiReasoningTag} suggested applied noCount />
        </View>

        <Typography
          variant="body1"
          style={{ margin: '24px 0 0', overflow: 'auto' }}
        >
          {aiReasoningTag.ai_metadata?.reason}
        </Typography>

        <CloseOutlined
          sx={{
            position: 'absolute',
            top: '20px',
            right: '20px',
            borderRadius: '100%',
            border: '1px solid black',
            padding: '4px',
            cursor: 'pointer',
          }}
          onClick={hideAIReasoning}
        />
      </VStack>
    </VStack>
  );
}
