import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import {
  call,
  put,
  SagaReturnType,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import * as api from 'src/api/api';
import {
  calculateParentage,
  countCodes,
  countDemographics,
  decrementCodeCounts,
  incrementCodeCounts,
  squashCodeParentage,
} from 'src/components/Insights/utils/code';
import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { User } from 'src/types/auth';
import { Conversation, Snippet } from 'src/types/conversation';
import {
  AIMetadata,
  Catalog,
  CatalogDetailsResponse,
  CatalogFilter,
  CatalogFiltersResponse,
  Code,
  Codebook,
  CodeCount,
  ConversationsEntities,
  Demographic,
  EntriesRequest,
  EntriesResponse,
  Entry,
  Filter,
  FilterJoin,
  FilterName,
  FilterOperation,
  ImportedParticipantRow,
  PagingParameters,
  Participant,
} from 'src/types/insights';
import { OrganizationRole } from 'src/types/organization';
import { userFlags } from '../../Providers/FlagProvider';
import { callWithUser } from '../redux-helpers';

export type DisplayMode = 'compact' | 'expanded';
// For LoadingMode, 'fast' means we're hitting the api frequently, 'slow' means we're loading all data up front.
export type LoadingMode = 'slow' | 'fast';

interface CatalogState {
  authors: { [key: string]: User };
  catalog: Catalog;
  codebook: Codebook;
  codes: { [key: string]: Code };
  conversations: { [key: string]: Conversation };
  demographicCounts: Record<CodeCount['id'], CodeCount>;
  demographics: { [key: string]: Demographic };
  entries: { [key: string]: Entry };
  participants: { [key: string]: Participant };
  snippets: { [key: string]: Snippet };
  error: Error | undefined;
  loadingMode: LoadingMode;
  displayMode: DisplayMode;
  isLoading: boolean;
  selectedConversationId?: Conversation['id'];
  selectedEntryId?: Entry['id'];
  selectedParticipantId?: Participant['id'];
  sensemakers: User[];
  filters: CatalogFilter[];
  selectedFilters: Filter[];
  previouslySelectedFilters: Filter[];
  filterJoin: FilterJoin;
  initialFilter: Filter;
  codeCounts: Record<CodeCount['id'], CodeCount>;
  unassignedCoding?: Code['id'];
}

const initialState: CatalogState = {
  authors: {},
  catalog: {
    id: -1,
    organization_id: -1,
    title: '',
    description: '',
    entries_count: 0,
    total_duration: 0,
  },
  codebook: { id: -1, catalog_id: -1, title: '', description: '' },
  codes: {},
  conversations: {},
  demographicCounts: {},
  demographics: {},
  entries: {},
  participants: {},
  snippets: {},
  error: undefined,
  loadingMode: 'slow',
  displayMode: 'expanded',
  isLoading: false,
  selectedConversationId: undefined,
  selectedEntryId: undefined,
  selectedParticipantId: undefined,
  sensemakers: [],
  filters: [],
  selectedFilters: [],
  previouslySelectedFilters: [],
  filterJoin: FilterJoin.AND,
  initialFilter: {} as Filter,
  codeCounts: {},
  unassignedCoding: undefined,
};

const onRequestInitiated = (state: CatalogState, loading = false) => {
  state.error = undefined;
  state.isLoading = loading;
};

const onRequestFailure = (
  state: CatalogState,
  action: PayloadAction<Error>,
  message: string
) => {
  state.error = action.payload;
  state.isLoading = false;
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

const onRequestSuccess = (state: CatalogState) => {
  state.error = undefined;
  state.isLoading = false;
};

const onReset = (state: CatalogState) => {
  state.authors = {};
  state.catalog = {
    id: -1,
    organization_id: -1,
    title: '',
    description: '',
    entries_count: 0,
    total_duration: 0,
  };
  state.codebook = { id: -1, catalog_id: -1, title: '', description: '' };
  state.codes = {};
  state.conversations = {};
  state.demographicCounts = [];
  state.demographics = {};
  state.entries = {};
  state.participants = {};
  state.selectedConversationId = undefined;
  state.selectedEntryId = undefined;
  state.selectedParticipantId = undefined;
  state.filters = [];
  state.sensemakers = [];
  state.selectedFilters = [];
  state.initialFilter = {} as Filter;
};

const slice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    addConversations(
      state,
      action: PayloadAction<[Catalog['id'], Conversation['id'][]]>
    ) {
      onRequestInitiated(state, true);
    },
    addConversationsSuccess(state) {
      onRequestSuccess(state);
    },
    addConversationsFailure(state, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to add conversation(s).');
    },
    assignPrimarySpeaker(
      state: CatalogState,
      action: PayloadAction<
        [Catalog['id'], Entry['id'], Entry['primary_participant_id']]
      >
    ) {
      onRequestInitiated(state, false);
    },
    assignPrimarySpeakerSuccess(
      state: CatalogState,
      action: PayloadAction<[Entry['id'], Entry['primary_participant_id']]>
    ) {
      const [entryId, primarySpeakerId] = action.payload;
      const entry = state.entries[entryId];
      entry.primary_participant_id = primarySpeakerId;
      state.entries[entryId] = entry;
      onRequestSuccess(state);
    },
    assignPrimarySpeakerFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(
        state,
        action,
        'Failed to assign primary speaker to highlight.'
      );
    },
    assignCoding(
      state: CatalogState,
      action: PayloadAction<[Code['id'], Entry['id']]>
    ) {
      onRequestInitiated(state, false);
    },
    assignCodingSuccess(
      state: CatalogState,
      action: PayloadAction<[Code['id'], Entry['id']]>
    ) {
      onRequestSuccess(state);
      const [codeId, entryId] = action.payload;
      const newEntries = cloneDeep(state.entries);
      const entry = newEntries[`${entryId}`];
      if (entry === undefined) {
        return;
      }

      if (!entry.merged_codings.some((c) => c.code_id === codeId)) {
        // Adding codeId to entry if it does not already exist
        entry.merged_codings.push({
          code_id: codeId,
          is_applied: true,
          is_suggested: false,
          ai_metadata: {} as AIMetadata,
        });
      } else {
        // Updating existing coding object
        entry.merged_codings.forEach((c) => {
          if (c.code_id === codeId) {
            c.is_applied = true;
          }
        });
      }

      // Incrementing code counts
      let newCodeCounts = incrementCodeCounts(codeId, state.codeCounts);
      const parentId = state.codes[codeId].parentage.filter(
        (id) => id !== codeId
      )[0];
      if (parentId) {
        newCodeCounts = incrementCodeCounts(parentId, newCodeCounts);
      }
      state.entries = newEntries;
      state.codeCounts = newCodeCounts;
    },
    assignCodingFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to assign coding.');
    },
    assignDemographic(
      state: CatalogState,
      action: PayloadAction<[Demographic['id'], Participant['id']]>
    ) {
      onRequestInitiated(state, false);
    },
    assignDemographicSuccess(
      state: CatalogState,
      action: PayloadAction<[Demographic['id'], Participant['id']]>
    ) {
      onRequestSuccess(state);
      const [demographicId, participantId] = action.payload;
      const participant = state.participants[`${participantId}`];
      if (participant === undefined) {
        return;
      }

      // Adding demographicId to participant if it does not already exist
      const index = participant.demographic_ids.indexOf(demographicId);
      if (index === -1) {
        participant.demographic_ids.push(demographicId);
      }

      // Incrementing demographic counts
      const newDemographicCounts = incrementCodeCounts(
        demographicId,
        state.demographicCounts
      );
      const parentId = state.demographics[demographicId].parentage.filter(
        (id) => id !== demographicId
      )[0];
      if (parentId) {
        incrementCodeCounts(parentId, newDemographicCounts);
      }
      state.demographicCounts = newDemographicCounts;
    },
    assignDemographicFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to assign demographic.');
    },
    uploadDemographics(
      state: CatalogState,
      action: PayloadAction<[Catalog['id'], ImportedParticipantRow[]]>
    ) {
      onRequestInitiated(state, true);
    },
    uploadDemographicsFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      // it failed because there were matches not found in the db
      if (action.payload.message.includes('matches not found')) {
        onRequestFailure(
          state,
          {
            ...action,
            payload: {
              name: 'Unmatched Rows',
              message: action.payload.message,
            },
          },
          'Demographics bulk upload failed'
        );
        // it failed for another reason
      } else {
        onRequestFailure(state, action, 'Demographics bulk upload failed');
      }
    },
    createCode(state: CatalogState, action: PayloadAction<Omit<Code, 'id'>>) {
      onRequestInitiated(state, false);
    },
    createCodeSuccess(state: CatalogState, action: PayloadAction<Code>) {
      onRequestSuccess(state);
      const code = action.payload;
      const codes = calculateParentage<Code>({
        ...state.codes,
        [code.id]: code,
      });
      state.codes = codes;
      // Adjust code counts
    },
    createCodeFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to create code.');
    },
    createDemographic(
      state: CatalogState,
      action: PayloadAction<Omit<Demographic, 'id'>>
    ) {
      onRequestInitiated(state, false);
    },
    createDemographicSuccess(
      state: CatalogState,
      action: PayloadAction<Demographic>
    ) {
      onRequestSuccess(state);
      const demographic = { ...action.payload, code_type: 'demographic' };
      const demographics = calculateParentage<Demographic>({
        ...state.demographics,
        [demographic.id]: demographic,
      });
      state.demographics = demographics;
      state.demographicCounts = countDemographics(
        demographics,
        state.participants
      );
    },
    createDemographicFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to create demographic.');
    },
    deleteCatalog(
      state: CatalogState,
      action: PayloadAction<{ data: Catalog['id']; callback: () => void }>
    ) {
      onRequestInitiated(state);
      onReset(state);
    },
    deleteCatalogSuccess(state: CatalogState) {
      onRequestSuccess(state);
    },
    deleteCatalogFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to delete catalog.');
    },
    deleteCode(state: CatalogState, action: PayloadAction<Code['id']>) {
      onRequestInitiated(state, false);
    },
    deleteCodeSuccess(state: CatalogState, action: PayloadAction<Code['id']>) {
      onRequestSuccess(state);
      const codeId = action.payload;
      const codes = calculateParentage<Code>(
        Object.fromEntries(
          Object.entries(state.codes).filter((entry) => {
            const [id, code] = entry;
            return code.parentage.indexOf(codeId) === -1;
          })
        )
      );
      state.codes = codes;
      // Adjust Code Counts
    },
    deleteCodeFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to delete code.');
    },
    deleteConversations(
      state,
      action: PayloadAction<[Catalog['id'], Conversation['id'][]]>
    ) {
      onRequestInitiated(state, true);
    },
    deleteConversationsSuccess(state) {
      onRequestSuccess(state);
    },
    deleteConversationsFailure(state, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to delete conversation(s).');
    },
    deleteDemographic(
      state: CatalogState,
      action: PayloadAction<Demographic['id']>
    ) {
      onRequestInitiated(state, false);
    },
    deleteDemographicSuccess(
      state: CatalogState,
      action: PayloadAction<Demographic['id']>
    ) {
      onRequestSuccess(state);
      const demographicId = action.payload;
      const demographics = calculateParentage<Demographic>(
        Object.fromEntries(
          Object.entries(state.demographics).filter((entry) => {
            const [id, demographic] = entry;
            return demographic.parentage.indexOf(demographicId) === -1;
          })
        )
      );
      state.demographics = demographics;
      state.demographicCounts = countDemographics(
        demographics,
        state.participants
      );
    },
    deleteDemographicFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to delete demographic.');
    },
    loadCatalog(state: CatalogState, action: PayloadAction<Catalog['id']>) {
      onRequestInitiated(state, true);
      onReset(state);
    },
    loadCatalogSuccess(
      state: CatalogState,
      action: PayloadAction<
        CatalogDetailsResponse & { filters: CatalogFiltersResponse }
      >
    ) {
      const { entities, filters } = action.payload;

      // Sort Filters
      const filterOrder = Object.keys(FilterName);
      let filtersSortedByName = [...filters]
        .sort(
          (a, b) =>
            (filterOrder.indexOf(a.filter_name) >= 0
              ? filterOrder.indexOf(a.filter_name)
              : 100) -
            (filterOrder.indexOf(b.filter_name) >= 0
              ? filterOrder.indexOf(b.filter_name)
              : 100)
        )
        .filter((f) => f.filter_values.length);

      // Sort Filter Oprerations
      const filterOperationOrder = Object.values(FilterOperation);
      filtersSortedByName = filtersSortedByName.map((f) => ({
        ...f,
        operations: f.operations.sort(
          (a, b) =>
            filterOperationOrder.indexOf(a) - filterOperationOrder.indexOf(b)
        ),
      }));

      // Create Initial Filter
      const initialFilter: Filter = {
        filter_name: filtersSortedByName[0]?.filter_name || '',
        operation: filtersSortedByName[0]?.operations[0] || '',
        values: [],
      };

      const getCodes = userFlags?.ai_tagging
        ? squashCodeParentage
        : calculateParentage;

      const codes = getCodes<Code>(entities.codes || {});

      // Guarantee demographics have the "demographic" type and color
      const demographics = calculateParentage<Demographic>(
        Object.fromEntries(
          Object.entries(entities.demographics || {}).map((entry) => {
            const [key, value] = entry;
            return [
              key,
              {
                ...value,
                code_type: 'demographic',
                color: value.color ? value.color : '#ffffff',
              },
            ];
          })
        )
      );

      state.catalog = Object.values(entities.catalogs)[0];
      state.codebook = Object.values(entities.codebooks)[0];
      state.codes = codes;
      state.demographics = demographics;
      state.initialFilter = initialFilter;
      state.selectedFilters = [{ ...initialFilter }];
      state.filters = filters;
      onRequestSuccess(state);
    },
    loadCatalogFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to load catalog.');
      state.catalog = {
        id: -1,
        organization_id: -1,
        title: '',
        description: '',
        entries_count: 0,
        total_duration: 0,
      };
    },
    loadEntries(state: CatalogState, action: PayloadAction<PagingParameters>) {
      onRequestInitiated(state, true);
    },
    loadEntriesSuccess(
      state: CatalogState,
      action: PayloadAction<EntriesResponse>
    ) {
      const { entities, codebook_counts } = action.payload;
      const entries = entities.catalog_entries;
      const participants = entities.participants || {};
      const snippets = entities.snippets || {};

      // Add in missing conversation IDs to participants
      Object.values(entries).map((entry) => {
        (entry.participant_ids || []).map((id) => {
          const participant = participants[`${id}`];
          if (participant !== undefined) {
            participant.conversation_id = entry.conversation_id;
          }
        });
      });

      // Add "No speaker assigned" option for participant filtering
      // Commented out for now until "No Speaker Assigned" feature implemented w/ local state
      // const unnasignedParticipant = {
      //   '-1': {
      //     id: -1,
      //     name: 'No speaker assigned',
      //     demographic_ids: [],
      //     conversation_id: -1,
      //   },
      // };

      state.authors = entities.authors || {};
      state.codeCounts = countCodes(state.codes, codebook_counts);
      state.conversations = entities.conversations || {}; // may be redundant
      state.demographicCounts = countDemographics(
        state.demographics,
        participants
      );
      state.entries = entries;
      state.participants = participants;
      state.snippets = snippets;
      // Use below and not above after above code gets utilized
      // state.participants = { ...participants, ...unnasignedParticipant };
      onRequestSuccess(state);
    },
    loadEntriesFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to load Entries.');
    },
    loadConversations(
      state: CatalogState,
      action: PayloadAction<Catalog['id']>
    ) {
      onRequestInitiated(state, true);
    },
    loadConversationsSuccess(
      state: CatalogState,
      action: PayloadAction<ConversationsEntities>
    ) {
      state.conversations = action.payload.conversations || [];
      onRequestSuccess(state);
    },
    loadConversationsFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to load Conversations.');
    },
    markInternal(
      state: CatalogState,
      action: PayloadAction<[Entry['id'], Entry['is_internal']]>
    ) {
      onRequestInitiated(state, false);
    },
    markInternalSuccess(
      state: CatalogState,
      action: PayloadAction<[Entry['id'], Entry['is_internal']]>
    ) {
      const [entryId, isInternal] = action.payload;
      const entry = state.entries[`${entryId}`];
      if (!entry) {
        return;
      }
      entry.is_internal = isInternal;
      onRequestSuccess(state);
    },
    markInternalFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(
        state,
        action,
        'Failed to exclude highlight from portal'
      );
    },
    selectConversation(
      state,
      action: PayloadAction<Conversation['id'] | undefined>
    ) {
      state.selectedConversationId = action.payload;
      state.selectedParticipantId = undefined; // always reset when changing conversation
    },
    selectEntry(state, action: PayloadAction<Entry['id'] | undefined>) {
      state.unassignedCoding = undefined;
      state.selectedEntryId = action.payload;
    },

    selectParticipant(
      state,
      action: PayloadAction<Participant['id'] | undefined>
    ) {
      state.selectedParticipantId = action.payload;
    },
    setDisplayMode(state, action: PayloadAction<DisplayMode>) {
      state.displayMode = action.payload;
    },
    setLoadingMode(state, action: PayloadAction<LoadingMode>) {
      state.loadingMode = action.payload;
    },
    setAllConversations(
      state,
      action: PayloadAction<{
        callback: (allConversations: Conversation[]) => void;
      }>
    ) {
      return;
    },
    setError(state, action: PayloadAction<Error | undefined>) {
      state.error = action.payload;
    },
    unassignCoding(
      state: CatalogState,
      action: PayloadAction<[Code['id'], Entry['id']]>
    ) {
      onRequestInitiated(state, false);
    },
    unassignCodingSuccess(
      state: CatalogState,
      action: PayloadAction<[Code['id'], Entry['id']]>
    ) {
      onRequestSuccess(state);
      const [codeId, entryId] = action.payload;
      state.unassignedCoding = codeId;
      const entry = state.entries[`${entryId}`];
      if (entry === undefined) {
        return;
      }

      // Unapplying codeId in entry if it exist
      entry.merged_codings = entry.merged_codings.map((c) => {
        if (c.code_id === codeId) {
          c.is_applied = false;
        }
        return c;
      });

      // Decrementing demographic counts
      let newCodeCounts = decrementCodeCounts(codeId, state.codeCounts);
      const parentId = state.demographics[codeId]?.parentage.filter(
        (id) => id !== codeId
      )[0];
      if (parentId) {
        newCodeCounts = decrementCodeCounts(codeId, newCodeCounts);
      }
      state.codeCounts = newCodeCounts;
    },
    unassignCodingFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to unassign coding.');
    },
    unassignDemographic(
      state: CatalogState,
      action: PayloadAction<[Demographic['id'], Participant['id']]>
    ) {
      onRequestInitiated(state, false);
    },
    unassignDemographicSuccess(
      state: CatalogState,
      action: PayloadAction<[Demographic['id'], Participant['id']]>
    ) {
      onRequestSuccess(state);
      const [demographicId, participantId] = action.payload;
      const participant = state.participants[`${participantId}`];
      if (participant === undefined) {
        return;
      }

      // Removing demographicId from participant if it exist
      const index = participant.demographic_ids.indexOf(demographicId);
      if (index !== -1) {
        participant.demographic_ids.splice(index, 1);
      }

      // Decrementing demographic counts
      let newDemographicCounts = decrementCodeCounts(
        demographicId,
        state.demographicCounts
      );
      const parentId = state.demographics[demographicId]?.parentage.filter(
        (id) => id !== demographicId
      )[0];
      if (parentId) {
        newDemographicCounts = decrementCodeCounts(
          parentId,
          newDemographicCounts
        );
      }
      state.demographicCounts = newDemographicCounts;
    },
    unassignDemographicFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to unassign demographic.');
    },
    updateCatalog(state: CatalogState, action: PayloadAction<Catalog>) {
      onRequestInitiated(state);
      state.catalog = action.payload;
    },
    updateCatalogSuccess(state: CatalogState, action: PayloadAction<Catalog>) {
      onRequestSuccess(state);
      state.catalog = action.payload;
    },
    updateCatalogFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to update catalog.');
    },
    updateCode(state: CatalogState, action: PayloadAction<Code>) {
      onRequestInitiated(state, false);
      const code = action.payload;
      const reparentedCodes = calculateParentage<Code>({
        ...state.codes,
        [code.id]: code,
      });

      // Adjust the codebook counts for the new parantage.
      const matchingCode = state.codeCounts[code.id];
      const newParentId = code.parent_id ?? -1;
      const oldParentId = code.parentage.filter((id) => id !== code.id)[0];
      let newCodeCounts = incrementCodeCounts(
        newParentId,
        state.codeCounts,
        matchingCode?.count ?? 0
      );
      newCodeCounts = decrementCodeCounts(
        oldParentId,
        newCodeCounts,
        matchingCode?.count ?? 0
      );
      state.codes = reparentedCodes;
      state.codeCounts = newCodeCounts;
    },
    updateCodeSuccess(state: CatalogState, action: PayloadAction<void>) {
      onRequestSuccess(state);
    },
    updateCodeFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to update code.');
    },
    updateDemographic(state: CatalogState, action: PayloadAction<Demographic>) {
      onRequestInitiated(state, false);
      const participant = action.payload;
      state.demographics = calculateParentage({
        ...state.demographics,
        [participant.id]: participant,
      });
      state.demographicCounts = countDemographics(
        state.demographics,
        state.participants
      );
    },
    updateDemographicSuccess(state: CatalogState, action: PayloadAction<void>) {
      onRequestSuccess(state);
    },
    updateDemographicFailure(
      state: CatalogState,
      action: PayloadAction<Error>
    ) {
      onRequestFailure(state, action, 'Failed to update demographic.');
    },
    setEntriesStatus(
      state: CatalogState,
      action: PayloadAction<[Catalog['id'], Entry['id'][], Entry['status']]>
    ) {
      onRequestInitiated(state, false);
    },
    setEntriesStatusSuccess(
      state: CatalogState,
      action: PayloadAction<[Entry['id'][], Entry['status']]>
    ) {
      const [entryIds, status] = action.payload;
      entryIds.forEach((entryId) => {
        const entry = state.entries[entryId];
        if (entry) {
          // We may have Ids that have been filtered out
          entry.status = status;
          state.entries[entryId] = entry;
        }
      });
      onRequestSuccess(state);
    },
    setEntriesStatusFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to update entry status.');
    },
    refreshEntries(
      state: CatalogState,
      action: PayloadAction<
        Pick<EntriesRequest, 'annotation_ids' | 'catalogId'>
      >
    ) {
      onRequestInitiated(state, false);
    },
    refreshEntriesSuccess(
      state: CatalogState,
      action: PayloadAction<EntriesResponse>
    ) {
      const { entities } = action.payload;
      const { catalog_entries: entries, participants } = entities;
      const refreshedEntries = { ...state.entries, ...entries };
      state.entries = refreshedEntries;
      // No need to refresh counts as these refreshes are for unapplied codes only
      onRequestSuccess(state);
    },
    refreshEntriesFailure(state: CatalogState, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to refresh entries.');
    },
    loadSensemakers(state) {
      onRequestInitiated(state, true);
    },
    loadSensemakersSuccess(state, action: PayloadAction<User[]>) {
      state.sensemakers = action.payload;
      onRequestSuccess(state);
    },
    loadSensemakersFailure(state, action: PayloadAction<Error>) {
      onRequestFailure(state, action, 'Failed to retrieve sensemakers.');
    },
    updateFilterJoin(state: CatalogState, action: PayloadAction<FilterJoin>) {
      state.filterJoin = action.payload;
    },
    updateFilters(state: CatalogState, action: PayloadAction<Filter[]>) {
      state.previouslySelectedFilters = state.selectedFilters;
      state.selectedFilters = action.payload;
      onRequestInitiated(state, true);
    },
    updateFiltersSuccess(
      state: CatalogState,
      action: PayloadAction<EntriesResponse>
    ) {
      const { entities } = action.payload;
      const { catalog_entries: entries, participants } = entities;

      state.demographicCounts = countDemographics(
        state.demographics,
        participants ?? []
      );
      state.entries = entries;
      onRequestSuccess(state);
    },
    updateFiltersFailure(state: CatalogState, action: PayloadAction<Error>) {
      state.selectedFilters = state.previouslySelectedFilters;
      onRequestFailure(state, action, 'Filters provided were invalid.');
    },
  },
});

export const {
  addConversations,
  addConversationsSuccess,
  addConversationsFailure,
  assignPrimarySpeaker,
  assignPrimarySpeakerFailure,
  assignPrimarySpeakerSuccess,
  assignCoding,
  assignCodingFailure,
  assignCodingSuccess,
  assignDemographic,
  assignDemographicFailure,
  assignDemographicSuccess,
  uploadDemographics,
  uploadDemographicsFailure,
  createCode,
  createCodeFailure,
  createCodeSuccess,
  createDemographic,
  createDemographicFailure,
  createDemographicSuccess,
  deleteCatalog,
  deleteCatalogFailure,
  deleteCatalogSuccess,
  deleteCode,
  deleteCodeFailure,
  deleteCodeSuccess,
  deleteConversations,
  deleteConversationsSuccess,
  deleteConversationsFailure,
  deleteDemographic,
  deleteDemographicFailure,
  deleteDemographicSuccess,
  loadCatalog,
  loadCatalogFailure,
  loadCatalogSuccess,
  loadEntries,
  loadEntriesSuccess,
  loadEntriesFailure,
  loadConversations,
  loadConversationsSuccess,
  loadConversationsFailure,
  markInternal,
  markInternalSuccess,
  markInternalFailure,
  selectConversation,
  selectEntry,
  selectParticipant,
  setError,
  setLoadingMode,
  setAllConversations,
  setDisplayMode,
  unassignCoding,
  unassignCodingFailure,
  unassignCodingSuccess,
  unassignDemographic,
  unassignDemographicFailure,
  unassignDemographicSuccess,
  updateCatalog,
  updateCatalogFailure,
  updateCatalogSuccess,
  updateCode,
  updateCodeFailure,
  updateCodeSuccess,
  updateDemographic,
  updateDemographicFailure,
  updateDemographicSuccess,
  setEntriesStatus,
  setEntriesStatusSuccess,
  setEntriesStatusFailure,
  refreshEntries,
  refreshEntriesSuccess,
  refreshEntriesFailure,
  loadSensemakers,
  loadSensemakersSuccess,
  loadSensemakersFailure,
  updateFilterJoin,
  updateFilters,
  updateFiltersSuccess,
  updateFiltersFailure,
} = slice.actions;

export const actions = slice.actions;

export default slice.reducer;

export function* sagaAddConversations(
  action: ReturnType<typeof addConversations>
) {
  const [catalogId, conversationIds] = action.payload;
  try {
    const response: SagaReturnType<typeof api.addConversationsToCatalog> =
      yield call(api.addConversationsToCatalog, catalogId, conversationIds);
    yield put(addConversationsSuccess());
    yield put(loadCatalog(catalogId));
  } catch (err) {
    yield put(addConversationsFailure(err as Error));
  }
}

export function* sagaAssignCoding(action: ReturnType<typeof assignCoding>) {
  const [codeId, entryId] = action.payload;
  try {
    const response: SagaReturnType<typeof api.assignCoding> = yield call(
      api.assignCoding,
      codeId,
      entryId
    );
    yield put(assignCodingSuccess([codeId, entryId]));
  } catch (err) {
    yield put(assignCodingFailure(err as Error));
  }
}

export function* sagaAssignPrimarySpeaker(
  action: ReturnType<typeof assignPrimarySpeaker>
) {
  const [catalogId, entryId, primarySpeakerId] = action.payload;
  try {
    const response: SagaReturnType<typeof api.assignPrimarySpeaker> =
      yield call(
        api.assignPrimarySpeaker,
        catalogId,
        entryId,
        primarySpeakerId
      );
    yield put(assignPrimarySpeakerSuccess([entryId, primarySpeakerId]));
  } catch (err) {
    yield put(assignPrimarySpeakerFailure(err as Error));
  }
}

export function* sagaAssignDemographic(
  action: ReturnType<typeof assignDemographic>
) {
  const [demographicId, participantId] = action.payload;
  try {
    const response: SagaReturnType<typeof api.assignDemographic> = yield call(
      api.assignDemographic,
      demographicId,
      participantId
    );
    yield put(assignDemographicSuccess([demographicId, participantId]));
  } catch (err) {
    yield put(assignDemographicFailure(err as Error));
  }
}

export function* sagaUploadDemographics(
  action: ReturnType<typeof uploadDemographics>
) {
  const [catalog_id, demographics] = action.payload;
  try {
    const response: SagaReturnType<typeof api.uploadDemographics> = yield call(
      api.uploadDemographics,
      catalog_id,
      demographics
    );
    yield put(loadCatalog(catalog_id));
  } catch (err) {
    yield put(uploadDemographicsFailure(err as Error));
  }
}

export function* sagaCreateCode(action: ReturnType<typeof createCode>) {
  try {
    const code: SagaReturnType<typeof api.createCode> = yield call(
      api.createCode,
      action.payload
    );
    yield put(createCodeSuccess(code));
  } catch (err) {
    yield put(createCodeFailure(err as Error));
  }
}

export function* sagaCreateDemographic(
  action: ReturnType<typeof createDemographic>
) {
  try {
    const demographic: SagaReturnType<typeof api.createDemographic> =
      yield call(api.createDemographic, action.payload);
    yield put(createDemographicSuccess(demographic));
  } catch (err) {
    yield put(createDemographicFailure(err as Error));
  }
}

export function* sagaDeleteCatalog(action: ReturnType<typeof deleteCatalog>) {
  try {
    const response: SagaReturnType<typeof api.deleteCatalog> = yield call(
      api.deleteCatalog,
      action.payload.data
    );
    yield put(deleteCatalogSuccess());
    yield call(action.payload.callback);
  } catch (err) {
    yield put(deleteCatalogFailure(err as Error));
  }
}

export function* sagaDeleteCode(action: ReturnType<typeof deleteCode>) {
  try {
    const response: SagaReturnType<typeof api.deleteCode> = yield call(
      api.deleteCode,
      action.payload
    );
    yield put(deleteCodeSuccess(action.payload));
  } catch (err) {
    yield put(deleteCodeFailure(err as Error));
  }
}

export function* sagaDeleteDemographic(
  action: ReturnType<typeof deleteDemographic>
) {
  try {
    const response: SagaReturnType<typeof api.deleteDemographic> = yield call(
      api.deleteDemographic,
      action.payload
    );
    yield put(deleteDemographicSuccess(action.payload));
  } catch (err) {
    yield put(deleteDemographicFailure(err as Error));
  }
}

export function* sagaDeleteConversations(
  action: ReturnType<typeof deleteConversations>
) {
  const [catalogId, conversationIds] = action.payload;
  try {
    const response: SagaReturnType<typeof api.deleteConversationsFromCatalog> =
      yield call(
        api.deleteConversationsFromCatalog,
        catalogId,
        conversationIds
      );
    yield put(deleteConversationsSuccess());
    yield put(loadCatalog(catalogId));
  } catch (err) {
    yield put(deleteConversationsFailure(err as Error));
  }
}

export function* sagaLoadCatalog(action: ReturnType<typeof loadCatalog>) {
  try {
    const catalogId = action.payload;
    const catalogData: SagaReturnType<typeof api.getCatalog> = yield call(
      api.getCatalog,
      catalogId
    );
    const catalogFilters: SagaReturnType<typeof api.getCatalogFilters> =
      yield call(api.getCatalogFilters, catalogId);
    const conversations: SagaReturnType<typeof api.getInsightsConversations> =
      yield call(api.getInsightsConversations, action.payload);
    const entriesLength =
      catalogData.entities.catalogs[catalogId].entries_count;
    const loadingMode: LoadingMode =
      entriesLength && entriesLength > 10000 ? 'fast' : 'slow';
    yield put(setLoadingMode(loadingMode));
    if (loadingMode === 'slow') {
      const response: SagaReturnType<typeof api.getEntries> = yield call(
        api.getEntries,
        {
          catalogId,
        }
      );
      yield put(
        loadCatalogSuccess({ ...catalogData, filters: catalogFilters })
      );
      yield put(loadEntriesSuccess(response));
      yield put(loadConversationsSuccess(conversations));
    } else {
      const options = {
        limit: 50,
        page: 1,
      };
      const response: SagaReturnType<typeof api.getEntries> = yield call(
        api.getEntries,
        {
          catalogId,
          limit: options.limit,
          page: options.page,
        }
      );
      yield put(
        loadCatalogSuccess({ ...catalogData, filters: catalogFilters })
      );
      yield put(loadEntriesSuccess(response));
      yield put(loadConversationsSuccess(conversations));
    }
  } catch (err) {
    yield put(loadCatalogFailure(err as Error));
  }
}

export function* sagaLoadSensemakers(
  action: ReturnType<typeof loadSensemakers>
) {
  try {
    const organizationId: number = yield select(
      (state) => state.catalog.catalog.organization_id
    );
    const catalogId: number = yield select((state) => state.catalog.catalog.id);
    const orgMembers: SagaReturnType<typeof api.getUsersInOrganization> =
      yield call(api.getUsersInOrganization, organizationId);

    // Grab all sensemakers with permission to that catalog (ignores org sensemaker permission)
    const sensemakers = orgMembers.filter((m) =>
      m.roles.some(
        (role) =>
          role.catalog_id === catalogId &&
          role.role_type.toLowerCase() ===
            OrganizationRole.sensemaker.toLowerCase()
      )
    );
    yield put(loadSensemakersSuccess(sensemakers));
  } catch (err) {
    yield put(loadSensemakersFailure(err as Error));
  }
}

export function* sagaLoadAllConversations(
  action: ReturnType<typeof setAllConversations>
) {
  try {
    const organizationId: number = yield select(
      (state) => state.catalog.catalog.organization_id
    );
    const allConversations: SagaReturnType<typeof api.getConversations> =
      yield callWithUser(api.getConversations, {
        organizationIds: [organizationId],
      });
    yield call(
      action.payload.callback,
      Object.values(allConversations.entities.conversations)
    );
  } catch (err) {
    yield put(loadEntriesFailure(err as Error));
  }
}
export function* sagaLoadEntries(action: ReturnType<typeof loadEntries>) {
  try {
    const { catalogId, limit, page } = action.payload;
    const response: SagaReturnType<typeof api.getEntries> = yield call(
      api.getEntries,
      {
        catalogId,
        limit,
        page,
      }
    );
    yield put(loadEntriesSuccess(response));
  } catch (err) {
    yield put(loadEntriesFailure(err as Error));
  }
}

export function* sagaLoadConversations(
  action: ReturnType<typeof loadConversations>
) {
  try {
    const conversations: SagaReturnType<typeof api.getInsightsConversations> =
      yield call(api.getInsightsConversations, action.payload);
    yield put(loadConversationsSuccess(conversations));
  } catch (err) {
    yield put(loadConversationsFailure(err as Error));
  }
}

export function* sagaMarkInternal(action: ReturnType<typeof markInternal>) {
  try {
    // retrieve the current catalog to use its' ID
    const catalog: Catalog = yield select(catalogSelectors.getCatalog);
    const response: SagaReturnType<typeof api.markInternal> = yield call(
      api.markInternal,
      catalog.id,
      ...action.payload
    );
    yield put(markInternalSuccess(action.payload));
  } catch (err) {
    yield put(markInternalFailure(err as Error));
  }
}

export function* sagaUnassignCoding(action: ReturnType<typeof unassignCoding>) {
  const [codeId, entryId] = action.payload;
  try {
    const response: SagaReturnType<typeof api.unassignCoding> = yield call(
      api.unassignCoding,
      codeId,
      entryId
    );
    yield put(unassignCodingSuccess([codeId, entryId]));
  } catch (err) {
    yield put(unassignCodingFailure(err as Error));
  }
}

export function* sagaUnassignDemographic(
  action: ReturnType<typeof unassignDemographic>
) {
  const [demographicId, participantId] = action.payload;
  try {
    const response: SagaReturnType<typeof api.unassignDemographic> = yield call(
      api.unassignDemographic,
      demographicId,
      participantId
    );
    yield put(unassignDemographicSuccess([demographicId, participantId]));
  } catch (err) {
    yield put(unassignDemographicFailure(err as Error));
  }
}

export function* sagaUpdateCatalog(action: ReturnType<typeof updateCatalog>) {
  try {
    const catalog: SagaReturnType<typeof api.updateCatalog> = yield call(
      api.updateCatalog,
      action.payload
    );
    yield put(updateCatalogSuccess(catalog));
  } catch (err) {
    yield put(updateCatalogFailure(err as Error));
  }
}

export function* sagaUpdateCode(action: ReturnType<typeof updateCode>) {
  try {
    const response: SagaReturnType<typeof api.updateCode> = yield call(
      api.updateCode,
      action.payload
    );
    yield put(updateCodeSuccess());
  } catch (err) {
    yield put(updateCodeFailure(err as Error));
  }
}

export function* sagaUpdateDemographic(
  action: ReturnType<typeof updateDemographic>
) {
  try {
    const response: SagaReturnType<typeof api.updateDemographic> = yield call(
      api.updateDemographic,
      action.payload
    );
    yield put(updateDemographicSuccess());
  } catch (err) {
    yield put(updateDemographicFailure(err as Error));
  }
}

export function* sagaSetEntriesStatus(
  action: ReturnType<typeof setEntriesStatus>
) {
  const [catalogId, entryIds, status] = action.payload;
  try {
    const response: SagaReturnType<typeof api.setEntriesStatus> = yield call(
      api.setEntriesStatus,
      catalogId,
      entryIds,
      status
    );
    yield put(actions.setEntriesStatusSuccess([entryIds, status]));
  } catch (err) {
    yield put(actions.setEntriesStatusFailure(err as Error));
  }
}

export function* sagaRefreshEntries(action: ReturnType<typeof refreshEntries>) {
  try {
    const response: SagaReturnType<typeof api.getEntries> = yield call(
      api.getEntries,
      {
        ...action.payload,
      }
    );
    yield put(refreshEntriesSuccess(response));
  } catch (e) {
    yield put(refreshEntriesFailure(e as Error));
  }
}

export function* sagaUpdateFilters(action: ReturnType<typeof updateFilters>) {
  try {
    const catalog: Catalog = yield select(catalogSelectors.getCatalog);
    const filter_join: FilterJoin = yield select(
      catalogSelectors.getFilterJoin
    );
    const filters: string = yield select((state) =>
      catalogSelectors.getFiltersQuery(state, action.payload, filter_join)
    );
    const response: SagaReturnType<typeof api.getEntries> = yield call(
      api.getEntries,
      {
        catalogId: catalog.id,
        filters,
      }
    );
    yield put(updateFiltersSuccess(response));
  } catch (e) {
    yield put(updateFiltersFailure(e as Error));
  }
}

export const sagas = [
  takeEvery(assignCoding.type, sagaAssignCoding),
  takeEvery(assignPrimarySpeaker.type, sagaAssignPrimarySpeaker),
  takeEvery(assignDemographic.type, sagaAssignDemographic),
  takeEvery(markInternal.type, sagaMarkInternal),
  takeEvery(uploadDemographics.type, sagaUploadDemographics),
  takeEvery(unassignCoding.type, sagaUnassignCoding),
  takeEvery(unassignDemographic.type, sagaUnassignDemographic),
  takeEvery(setEntriesStatus.type, sagaSetEntriesStatus),
  takeEvery(refreshEntries.type, sagaRefreshEntries),
  takeLatest(addConversations.type, sagaAddConversations),
  takeLatest(createCode.type, sagaCreateCode),
  takeLatest(createDemographic.type, sagaCreateDemographic),
  takeLatest(deleteCatalog.type, sagaDeleteCatalog),
  takeLatest(deleteCode.type, sagaDeleteCode),
  takeLatest(deleteConversations.type, sagaDeleteConversations),
  takeLatest(deleteDemographic.type, sagaDeleteDemographic),
  takeLatest(loadCatalog.type, sagaLoadCatalog),
  takeLatest(loadEntries.type, sagaLoadEntries),
  takeLatest(loadConversations.type, sagaLoadConversations),
  takeLatest(updateCatalog.type, sagaUpdateCatalog),
  takeLatest(updateCode.type, sagaUpdateCode),
  takeLatest(updateDemographic.type, sagaUpdateDemographic),
  takeLatest(setAllConversations.type, sagaLoadAllConversations),
  takeLatest(loadSensemakers.type, sagaLoadSensemakers),
  takeEvery(updateFilters.type, sagaUpdateFilters),
];
