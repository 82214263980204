import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import {
  getUsers,
  loadCatalogs,
  setCurrentUser,
} from 'src/redux/admin/admin-slice';
import MemberCatalogsTable from './MemberCatalogsTable/MemberCatalogsTable';
import MemberCollectionsTable from './MemberCollectionsTable/MemberCollectionsTable';
import MemberCommunitiesTable from './MemberCommunitesTable/MemberCommunitiesTable';
import MemberDetails from './MemberDetails/MemberDetails';

import styles from './ViewMember.module.scss';

type ViewMemberProps = {
  backToListLabel: string;
};

const ViewMember = ({ backToListLabel }: ViewMemberProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [query, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });
  const { userId } = query;

  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const members = useSelector(adminSelectors.getUsers);
  const currentMember = useSelector(adminSelectors.getCurrentUser);
  const isLoadingUsers = useSelector(adminSelectors.isLoadingUsers);
  const isLoadingCatalogs = useSelector(adminSelectors.isLoadingCatalogs);

  const [startedLoadingCatalogs, setStartedLoadingCatalogs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (startedLoadingCatalogs && !isLoadingCatalogs && !isLoadingUsers) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [startedLoadingCatalogs, isLoadingCatalogs, isLoadingUsers]);

  useEffect(() => {
    if (!userId) {
      //return to the Team List
      setQuery({ page: undefined, userId: undefined });
    } else if (!members.length) {
      // get fresh members
      dispatch(getUsers({ userType: 'members', organizationId }));
    } else if (!currentMember || currentMember.id !== userId) {
      dispatch(setCurrentUser({ id: userId }));
    }
  }, [userId, members, currentMember, dispatch, organizationId, setQuery]);

  useEffect(() => {
    // make loading catalogs the last step so we can make sure we visually load until everything is done
    // (we're turning on/off a single `isLoadingUsers` flag for multiple operations in the slice so it flipflops when unrelated operations
    // get kicked off above us)
    if (userId && organizationId && members.length && !startedLoadingCatalogs) {
      dispatch(loadCatalogs());
      setStartedLoadingCatalogs(true);
    }
  }, [dispatch, userId, organizationId, members, startedLoadingCatalogs]);

  return (
    <div className={cx(styles.fixedWidth)}>
      <Button
        color={'link'}
        onClick={() =>
          setQuery({ userId: undefined, page: undefined }, 'pushIn')
        }
        icon={['far', 'arrow-left']}
        content={backToListLabel}
      />
      {isLoading ? (
        <div className="w-100 text-center">
          <LoadingSpinner thick size="lg" active theme="dark" />
        </div>
      ) : !currentMember ? (
        <div>{t('admin.tab_team_view_member_not_exist')}</div>
      ) : (
        <div className={cx('d-flex flex-col')}>
          <div className={cx(styles.section)}>
            <Header />
            <MemberDetails />
          </div>
          <div className={cx(styles.section)}>
            <MemberCollectionsTable />
            <MemberCatalogsTable />
            <MemberCommunitiesTable />
          </div>
        </div>
      )}
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  const currentMember = useSelector(adminSelectors.getCurrentUser);

  const name = currentMember?.first_name ?? currentMember?.email;

  return (
    <div style={{ width: '100%' }}>
      <div className="d-flex">
        <span>
          {t('admin.member_helper_1', {
            name,
          })}
        </span>
      </div>
    </div>
  );
};

export default ViewMember;
