import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useField } from 'formik';

import AccordianTable, {
  AccordianTableProps,
} from 'src/components/AdminPage/Common/AccordianTable';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { UserCatalogMetadata } from 'src/types/admin';
import { Catalog } from 'src/types/insights';
import { createBasicProvider } from 'src/util/provider';
import CatalogSenseMakerToggle from '../../ViewMember/MemberCatalogsTable/CatalogSenseMakerToggle';

import styles from './CatalogsTableInput.module.scss';

type CatalogRowState = UserCatalogMetadata;

export type CatalogTableInputState = CatalogRowState[];

const [LocalProvider, LocalContext] = createBasicProvider<{
  catalogs: CatalogTableInputState;
  onSenseMakerChanged: (
    catalogId: Catalog['id'],
    isSenseMaker: boolean
  ) => void;
}>();

type CatalogsTableInputProps = {
  name: string;
} & Partial<AccordianTableProps<CatalogRowState>>;

const CatalogsTableInput = ({
  name,
  ...tableProps
}: CatalogsTableInputProps) => {
  const [_field, _meta, helpers] = useField<CatalogTableInputState>(name);

  const { setValue } = helpers;

  const allCatalogs = useSelector(adminSelectors.getCatalogs);
  const activeOrganizationId = useSelector(adminSelectors.getOrganizationId);

  const [catalogs, setCatalogs] = useState<CatalogTableInputState>(
    allCatalogs
      .filter((c) => c.organization_id === activeOrganizationId)
      .map((c) => ({
        catalog: c,
      }))
  );

  const onChange = useCallback(
    (state: CatalogTableInputState) => {
      setCatalogs(state);
      // only pass values up that aren't the defaults
      setValue(state.filter((s) => s.isSenseMaker));
    },
    [setCatalogs, setValue]
  );

  const onSenseMakerChanged = useCallback(
    (catalogId: number, isSenseMaker: boolean) => {
      const updatedCatalogs = catalogs.map((c) => {
        if (c.catalog.id === catalogId) {
          c.isSenseMaker = isSenseMaker;
        }
        return c;
      });

      onChange(updatedCatalogs);
    },
    [catalogs, onChange]
  );

  return (
    <LocalProvider value={{ catalogs, onSenseMakerChanged }}>
      <CatalogsTable {...tableProps} />
    </LocalProvider>
  );
};

const CatalogsTable = (
  props: Partial<AccordianTableProps<CatalogRowState>>
) => {
  const { t } = useTranslation();
  const { catalogs } = LocalContext();

  const tableProps: AccordianTableProps<CatalogRowState> = {
    ...props,
    startClosed: true,
    label: t('admin.catalog_accordian_label'),
    values: catalogs,
    testId: 'user-catalogs-table',
    valToKey: (state) => state.catalog.id,
    columns: [
      {
        id: 'catalog-name',
        headerLabel: t('admin.member_catalog_column_1'),
        widthFraction: 8,
        content: CatalogNameContent,
      },
      {
        id: 'catalog-role',
        headerLabel: t('admin.member_catalog_column_2'),
        widthFraction: 4,
        content: CatalogRoleContent,
      },
    ],
  };

  return <AccordianTable {...tableProps} />;
};

const CatalogNameContent = ({ val: state }: { val: CatalogRowState }) => {
  return (
    <Link
      className={cx(styles.title)}
      to={`/insights/catalog/${state.catalog.id}`}
    >
      {state.catalog.title}
    </Link>
  );
};

const CatalogRoleContent = ({ val: state }: { val: CatalogRowState }) => {
  const { onSenseMakerChanged } = LocalContext();
  return (
    <CatalogSenseMakerToggle
      catalogId={state.catalog.id}
      isSenseMaker={state.isSenseMaker}
      onToggle={() =>
        onSenseMakerChanged(state.catalog.id, !state.isSenseMaker)
      }
    />
  );
};

export default CatalogsTableInput;
