import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import { LoadingMode } from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { AbstractCode } from 'src/types/insights';

interface BaseProps {
  anchor: Element | null;
  closeMenu: () => void;
  label: string;
  openDialog: (key: string) => () => void;
  selectedCode: AbstractCode;
}

interface StateProps {
  loadingMode: LoadingMode;
}

type Props = BaseProps & StateProps & DispatchProp;

const mapStateToProps = (state: StoreState): StateProps => ({
  loadingMode: catalogSelectors.getLoadingMode(state),
});

//TODO: delete me after insights restyle feature flag
const CodeAccordionMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { anchor, closeMenu, label, openDialog, selectedCode } = props;
  const { t } = useTranslation();

  return (
    <Menu
      anchorEl={anchor}
      onClose={() => {
        closeMenu();
      }}
      open={Boolean(anchor)}
    >
      <MenuList>
        {selectedCode.id !== -2 && [
          <MenuItem key="update-code-menu-item" onClick={openDialog('update')}>
            <ListItemIcon>
              <MoveDownIcon />
            </ListItemIcon>
            <ListItemText>
              {t('insights.edit_value', { value: label })}
            </ListItemText>
          </MenuItem>,
        ]}
        {selectedCode.id !== -2 && [
          <Divider />,
          <MenuItem key="delete-code-menu-item" onClick={openDialog('delete')}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              {t('insights.delete_value', { value: label })}
            </ListItemText>
          </MenuItem>,
        ]}
      </MenuList>
    </Menu>
  );
};

CodeAccordionMenu.displayName = 'CodeAccordionMenu';

export default connect(mapStateToProps)(CodeAccordionMenu);
