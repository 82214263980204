import { useEffect, useMemo, useState } from 'react';
import {
  CheckCircleOutline,
  ExpandLessRounded,
  ExpandMoreRounded,
} from '@mui/icons-material';
import { Checkbox, Typography, TypographyProps } from '@mui/material';

import Color from 'src/assets/_util.scss';
import {
  ArrowLeftButton,
  ArrowRightButton,
} from 'src/components/core/Button/ArrowButton';
import Button from 'src/components/core/Button/Button';
import { HDivider, VDivider } from 'src/components/core/Divider';
import { Dropdown } from 'src/components/core/Dropdown';
import { HSpacer } from 'src/components/core/Spacer';
import { HStack, VStack } from 'src/components/core/Stack';
import { View } from 'src/components/core/View';
import type { BannerItem } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import {
  HighlightsPerPage,
  useHighlightsPageContext,
} from 'src/components/Insights/Catalog/HighlightsPageProvider';
import { HighlightsPageSelectionToolbar } from 'src/components/Insights/Catalog/HighlightsPageSelectionToolbar';
import SearchField from 'src/components/Insights/Sidebar/SearchField';
import ProgressRing from 'src/components/PipelineStatusIcon/ProgressRing';
import FilterPills from '../Filters/FilterPills';

export function HighlightsPageToolbar() {
  const {
    allHighlights,
    currentPage,
    setCurrentPage,
    clearSelection,
    search,
    setSearch,
    t,
    openFilters,
    setOpenFilters,
    resetFilters,
  } = useHighlightsPageContext();

  const highlightCount = allHighlights.length;
  const pageCount = Math.ceil(highlightCount / HighlightsPerPage);
  const onFirstPage = currentPage === 1;
  const onLastPage = currentPage === pageCount;
  const lowerBound = (currentPage - 1) * HighlightsPerPage + 1;
  const upperBound = Math.min(currentPage * HighlightsPerPage, highlightCount);

  useEffect(() => {
    // we could be looking at page 3, then add a filter that reduces the number of pages
    // to just 1, so this effect makes sure that we reset to the first page if that happens
    if (currentPage > pageCount) setCurrentPage(1);
  }, [pageCount]);

  const onChangeSearch = (text: string) => {
    setSearch(text);
    clearSelection();
  };

  return (
    <VStack
      style={{
        borderBottom: `1px solid ${Color.gray300}`,
        position: 'sticky',
        top: 0,
        zIndex: 10,
        background: Color.white,
        margin: '0 -12px',
        padding: '16px 12px',
      }}
    >
      <HStack
        style={{
          alignItems: 'center',
          gap: '16px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          color="plain"
          icon={['far', 'sliders-simple']}
          iconAfter
          onClick={() => {
            setOpenFilters((value) => !value);
            resetFilters();
          }}
          data-testid="insights_open_filters_button"
        >
          {openFilters ? 'Hide Filter' : 'Show Filter'}
        </Button>
        <SearchField
          labelKey="highlights.search"
          onChange={onChangeSearch}
          initialValue={search}
          sx={{ maxWidth: '300px', margin: 0 }}
        />

        <HSpacer grow />
        <Banner />
        <HSpacer grow />
        <SelectAllTool />
        <VDivider height="36px" />

        <HStack alignItems="center" gap="8px" flexShrink={0}>
          <Typography variant="body1">
            {t('insights.showing_range_description', {
              lowerBound,
              upperBound,
              count: highlightCount,
            })}
          </Typography>
          <HSpacer width="2px" />

          <ArrowLeftButton
            size={28}
            disabled={onFirstPage}
            onClick={() => setCurrentPage(currentPage - 1)}
            aria-label={t('common.previous_page')}
          />
          <ArrowRightButton
            size={28}
            disabled={onLastPage}
            onClick={() => setCurrentPage(currentPage + 1)}
            aria-label={t('common.next_page')}
          />
        </HStack>
      </HStack>

      <FilterPills />

      <HighlightsPageSelectionToolbar />
    </VStack>
  );
}

function Banner() {
  const AnimDuration = 300; // ms
  const { banners, hideBanner, t } = useHighlightsPageContext();
  const [expanded, setExpanded] = useState(false);

  // stores a local copy of the most-recently added banner
  // so that we can animate the banner away with its content still visible.
  const [mainBanner, setMainBanner] = useState<BannerItem>();

  const bannerCount = banners.length;
  const visible = bannerCount > 0;
  const canExpand = !!mainBanner?.details || bannerCount > 1;
  const ExpandIcon = expanded ? ExpandLessRounded : ExpandMoreRounded;

  const progress = useMemo(() => {
    let progress = 0;
    let progressSum = 0;
    banners.forEach((b) => {
      if (b.progress) {
        progress += b.progress;
        progressSum += 100;
      }
    });
    return progressSum
      ? (progress / progressSum) * 100
      : mainBanner?.progress ?? 0;
  }, [banners, mainBanner]);

  const onClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // store the most-recently added banner locally.
    // we use this to keep the banner content visible while it animates away.
    setMainBanner((prev) => banners.at(0) ?? prev);

    // set up auto-hide if needed
    banners.forEach((b) => {
      if (!b.progress) setTimeout(() => hideBanner(b.id), b.duration ?? 4000);
    });

    // collapse details if needed
    if (banners.length === 1 && !banners[0].details) setExpanded(false);
    if (banners.length === 0)
      setTimeout(() => setExpanded(false), AnimDuration);
  }, [banners, hideBanner]);

  return (
    <View
      sx={{
        position: 'absolute',
        top: '12px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
      }}
    >
      <VStack
        sx={{
          borderRadius: expanded ? '20px' : '100px',
          backgroundColor: Color.foraPurple,
          color: Color.white,
          p: '12px 16px',
          overflow: 'hidden',
          textWrap: 'none',
          boxShadow: expanded ? '0 0 8px rgba(0, 0, 0, 0.2)' : 'none',
          transitionProperty: 'opacity, filter, transform, box-shadow',
          transitionDuration: `${AnimDuration}ms`,
          transitionTimingFunction: 'ease-out',
          opacity: visible ? 1 : 0,
          filter: visible ? 'none' : 'blur(2px)',
          transform: visible ? '' : 'translate(0, 3px) scale(0.95)',
          pointerEvents: visible ? 'auto' : 'none',
          userSelect: 'none',
          cursor: canExpand ? 'pointer' : 'default',
          zIndex: 50,
        }}
      >
        <HStack
          style={{ alignItems: 'center', marginBottom: expanded ? '12px' : 0 }}
          onClick={onClick}
        >
          {(!!progress || bannerCount > 1) && (
            <View
              style={{
                position: 'relative',
                margin: '0 8px 0 0',
                width: '24px',
                aspectRatio: '1',
              }}
            >
              {bannerCount > 1 && (
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: Color.foraPurpleDark,
                    top: 0,
                    left: 0,
                    borderRadius: '100%',
                    background: Color.white,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {bannerCount}
                </Typography>
              )}
              {progress === 100 ? (
                <CheckCircleOutline style={{ color: Color.tealLight }} />
              ) : progress > 0 ? (
                <ProgressRing
                  style={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    top: '-4px',
                    left: '-4px',
                  }}
                  frontColor={Color.tealLight}
                  backColor={Color.white}
                  size={32}
                  thickness={2}
                  percent={progress}
                />
              ) : null}
            </View>
          )}
          <BannerMessage style={{ margin: '0 4px', fontWeight: '700' }}>
            {bannerCount > 1
              ? t('insights.tasksInProgress')
              : mainBanner?.message ?? ''}
          </BannerMessage>

          {canExpand && <ExpandIcon style={{ margin: '0 0 0 4px' }} />}
        </HStack>

        {expanded &&
          (bannerCount > 1 ? (
            <VStack style={{ margin: '12px 4px 4px', gap: '16px' }}>
              {banners?.map((banner, index) => (
                <VStack key={banner.id}>
                  <BannerMessage>{banner.message}</BannerMessage>
                  {banner.details}

                  {index !== bannerCount - 1 && (
                    <HDivider
                      color={Color.foraPurpleLight}
                      style={{ marginTop: '16px' }}
                    />
                  )}
                </VStack>
              ))}
            </VStack>
          ) : (
            mainBanner?.details ?? null
          ))}
      </VStack>
    </View>
  );
}

function BannerMessage({ style, ...props }: TypographyProps) {
  return (
    <Typography
      variant="body1"
      style={{ fontWeight: '600', flex: 1, ...style }}
      {...props}
    />
  );
}

function SelectAllTool() {
  const {
    visibleHighlights,
    allHighlights,
    selectedHighlightIds,
    setSelectedHighlightIds,
    t,
  } = useHighlightsPageContext();

  const modes = ['common.select_all', 'common.select_all_on_page'];
  type Mode = typeof modes[number];
  const [mode, _setMode] = useState<Mode>(modes[0]);

  const checked = useMemo(
    () => visibleHighlights?.every((h) => selectedHighlightIds.has(h.id)),
    [selectedHighlightIds, visibleHighlights]
  );

  const onChangeMode = (mode: Mode, enabled: boolean) => {
    if (mode === modes[0]) {
      setSelectedHighlightIds(
        new Set(enabled ? allHighlights.map((h) => h.id) : [])
      );
    } else if (mode === modes[1]) {
      setSelectedHighlightIds((prevSelected) => {
        const nowSelectedHighlights = new Set(prevSelected);

        visibleHighlights.forEach((h) =>
          nowSelectedHighlights[enabled ? 'add' : 'delete'](h.id)
        );

        return nowSelectedHighlights;
      });
    }
  };

  const setMode = (mode: Mode) => {
    _setMode(mode);
    onChangeMode(mode, true);
  };

  return (
    <HStack sx={{ alignItems: 'center', flexShrink: 0, position: 'relative' }}>
      <Checkbox
        size="small"
        style={{ color: Color[checked ? 'foraPurple' : 'gray650'] }}
        onChange={(e, checked) => onChangeMode(mode, checked)}
        checked={checked}
        aria-label={t(mode)}
        data-testid="select-all-checkbox"
      />

      <Dropdown
        items={modes.map((label) => ({ label }))}
        onSelect={(item) => setMode(item.label as Mode)}
      />
    </HStack>
  );
}
