import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { useAIContext } from 'src/components/Insights/Catalog/AIProvider';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { useCodebookContext } from 'src/components/Insights/Catalog/CodebookProvider';
import { TaggingBannerDetails } from 'src/components/Insights/Catalog/TaggingBannerDetails';
import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import { updateFilters } from 'src/redux/catalog/catalog-slice';
import { Entry, Filter, FilterJoin, FilterName } from 'src/types/insights';
import { createProvider } from 'src/util/provider';
import { useDelayedEffect } from 'src/util/useDelayed';

export const [HighlightsPageProvider, useHighlightsPageContext] =
  createProvider(() => {
    const catalogPageContext = useCatalogPageContext();
    const { allEntries, t } = catalogPageContext;

    const aiContext = useAIContext();
    const { taggingProgress } = aiContext;

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedHighlightIds, setSelectedHighlightIds] = useState(
      new Set<Entry['id']>()
    );
    const [banners, setBanners] = useState<BannerItem[]>([]);

    // ••• SEARCH VALUES & ACTIONS
    const { search, searchedEntries, setSearch } =
      useSearchedEntries(allEntries);

    // FE pagination - currently not true pagination, but kept as an aesthetic choice.
    // True pagination exists for catalogs with more than 10,000 entries; see sagaLoadCatalog to change that value.
    const pages = useMemo(
      () => _.chunk(searchedEntries, HighlightsPerPage),
      [searchedEntries]
    );
    const visibleHighlights = useMemo(
      () => pages[currentPage - 1],
      [pages, currentPage]
    );

    // ••• FILTER VALUES & ACTIONS

    const selectedFilters = useSelector(catalogSelectors.getSelectedFilters);
    const initialFilter = useSelector(catalogSelectors.getInitialFilter);
    const selectedFilterJoin = useSelector(catalogSelectors.getFilterJoin);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState<Filter[]>([...selectedFilters]);
    const [filterJoin, setFilterJoin] =
      useState<FilterJoin>(selectedFilterJoin);
    const resetFilters = useCallback(
      () => setFilters([...selectedFilters]),
      [selectedFilters]
    );

    // ••• Deselect VALUES & ACTIONS
    const { selectedEntry, dispatch } = catalogPageContext;
    const unassignedCoding = useSelector(catalogSelectors.getUnassignedCoding);
    const [refreshFilters, setRefreshFilters] = useState(false);
    const codeFilters = selectedFilters.filter((f) =>
      [
        FilterName.APPLIED_CODES,
        FilterName.AI_SUGGESTED_CODES,
        FilterName.SPEAKER_DEMOGRAPHICS,
      ].includes(f.filter_name)
    );

    useEffect(() => {
      // TODO: Rework after pagination is completed
      if (unassignedCoding) {
        // allows us to look at whatever code was unassigned
        // and check against filters. This approach will send
        // a refresh even if the user re-assigns the code in
        // the same edit session (ie. without closing the highlight modal)
        let refresh = false;
        codeFilters.forEach((f) => {
          if (f.values.includes(unassignedCoding)) {
            refresh = true;
          }
        });
        setRefreshFilters(refresh);
      }
    }, [codeFilters, unassignedCoding]);

    useEffect(() => {
      if (refreshFilters && !selectedEntry) {
        // Runs iff a user had entered the highlight modal view, made a change that
        // would require a new filter call, then closed the highlight modal view
        dispatch(updateFilters(selectedFilters));
        setRefreshFilters(false);
      }
    }, [selectedEntry, dispatch, selectedFilters, refreshFilters]);

    // ••• BANNER ACTIONS

    const showBanner = (
      props: Partial<BannerItem> & Pick<BannerItem, 'message'>
    ) => {
      const banner = {
        ...props,
        id: props.id ?? `${Math.random()}`,
      };
      setBanners((prev) => [banner, ...prev]);
      return banner;
    };

    /**
     * This fucntion updates the banner item that corresponds to the banner with the same ID
     *
     * Caution: Use this function discerningly, overwriting a BannerItem will result in a re-render
     * and may cause an infinite loop if the overwrite is identical to the original.
     * @param updatedProps - { id: string; message?: string; duration?: number; details?: ReactElement; progress?: number;}
     */
    const updateBanner = (
      updatedProps: Partial<BannerItem> & Pick<BannerItem, 'id'>
    ) => {
      setBanners((prev) => {
        const bannerIndex = prev.findIndex((b) => b.id === updatedProps.id);
        if (bannerIndex == -1) return prev;

        const newBanners = [...prev];
        newBanners[bannerIndex] = {
          ...newBanners[bannerIndex],
          ...updatedProps,
        };
        return newBanners;
      });
    };

    const hideBanner = (id: BannerItem['id']) => {
      setBanners((prev) => prev.filter((b) => b.id !== id));
    };

    // ••• TAGGING BANNER

    useEffect(() => {
      const { percent, totalCount } = taggingProgress;
      if (!totalCount) return;

      const bannerProps = {
        id: TaggingBannerId,
        message: t(
          percent === 100
            ? 'insights.taggingProgressBannerDone'
            : 'insights.taggingProgressBanner',
          { count: totalCount }
        ),
        details: <TaggingBannerDetails />,
        progress: percent,
      };

      const taggingBanner = banners.find((b) => b.id === TaggingBannerId);
      if (!taggingBanner && percent < 100) {
        showBanner(bannerProps);
      } else if (taggingBanner?.progress !== bannerProps.progress) {
        // Only update if progress has been made to avoid an infinite loop
        updateBanner(bannerProps);
      }
    }, [banners, t, taggingProgress]);

    useDelayedEffect(
      () => {
        if (taggingProgress.percent === 100 || !taggingProgress.totalCount)
          hideBanner(TaggingBannerId);
      },
      8000,
      [taggingProgress]
    );

    useEffect(() => {
      // Always ensure that a change in the applied filters updates the UI filters
      resetFilters();
    }, [resetFilters]);

    // ••• CONTEXT

    return {
      ...catalogPageContext,
      ...aiContext,
      ...useCodebookContext(),

      currentPage,
      setCurrentPage,

      visibleHighlights,
      allHighlights: searchedEntries,
      selectedHighlightIds,
      setSelectedHighlightIds,
      clearSelection: () => setSelectedHighlightIds(new Set()),

      openFilters,
      setOpenFilters,
      selectedFilterJoin,
      filterJoin,
      setFilterJoin,
      initialFilter,
      selectedFilters,
      filters,
      setFilters,
      resetFilters,
      search,
      setSearch,

      banners,
      showBanner,
      updateBanner,
      hideBanner,
    };
  });

// DEFINITIONS

export const HighlightsPerPage = 20;
const TaggingBannerId = 'taggingBanner';

export type BannerItem = {
  id: string;
  message: string;
  duration?: number;
  details?: ReactElement;

  /** percent: 0 - 100 */
  progress?: number;
};

const useSearchedEntries = (allEntries: Entry[]) => {
  const [search, setSearch] = useState<string>('');
  let searchedEntries = allEntries;
  if (search.length) {
    searchedEntries = allEntries.filter((e) =>
      [
        e.content?.toLowerCase(),
        e.user_name?.toLowerCase(),
        e.conversation_title?.toLowerCase(),
        e.description?.toLowerCase(),
        e.annotation_id,
      ]
        .join(' ')
        .includes(search.toLowerCase())
    );
  }
  return { searchedEntries, search, setSearch };
};
