import cloneDeep from 'lodash.clonedeep';

import { sortCodes } from 'src/components/Insights/utils/code';
import { valuelessOperations } from 'src/components/Insights/utils/filters';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Snippet } from 'src/types/conversation';
import {
  AbstractCode,
  Catalog,
  CatalogFilter,
  Code,
  CodeTag,
  Demographic,
  Entry,
  Filter,
  FilterJoin,
  FilterName,
  FilterOperation,
  Participant,
} from 'src/types/insights';
import { OrganizationRole } from 'src/types/organization';
import { DisplayMode, LoadingMode } from './catalog-slice';

export const selectors = {
  getAuthors: (state: StoreState): User[] => {
    return Object.values(state.catalog.authors);
  },
  getAuthorForEntry: (state: StoreState, entry: Entry): User => {
    return state.catalog.authors[entry.user_id];
  },
  getCatalog: (state: StoreState): Catalog => {
    return state.catalog.catalog;
  },
  getCodebook: (state: StoreState) => {
    return state.catalog.codebook;
  },
  getCodeCount: (state: StoreState, code: AbstractCode): number => {
    if (code.code_type === 'demographic') {
      return state.catalog.demographicCounts[code.id]?.count || 0;
    } else {
      return state.catalog.codeCounts[code.id]?.count || 0;
    }
  },
  getCodeTagsForEntry: (state: StoreState, entry: Entry): CodeTag[] => {
    return (
      entry.merged_codings?.map((coding) => {
        const code = state.catalog.codes[coding.code_id];
        return {
          ...code,
          ...coding,
        };
      }) ?? []
    );
  },
  getConversations: (state: StoreState): Conversation[] => {
    return Object.values(state.catalog.conversations);
  },
  getConversationByEntry: (state: StoreState, entry: Entry) => {
    return Object.values(state.catalog.conversations).find((conversation) => {
      return conversation.id === entry.conversation_id;
    });
  },
  getDemographics: (state: StoreState): Demographic[] => {
    return sortCodes(Object.values(state.catalog.demographics));
  },
  getDemographicsForEntry: (state: StoreState, entry: Entry): Demographic[] => {
    const codes: Demographic[] = [];
    (entry.participant_ids || []).map((id) => {
      const participant: Participant | undefined =
        state.catalog.participants[`${id}`];
      if (participant !== undefined) {
        participant.demographic_ids.map((id) => {
          const code: Demographic | undefined =
            state.catalog.demographics[`${id}`];
          if (code !== undefined) {
            codes.push(code);
          }
        });
      }
    });
    return codes;
  },
  getDemographicsForParticipants: (
    state: StoreState,
    participants: Participant[]
  ) => {
    const demographics: { [key: Participant['id']]: Demographic[] } = {};
    participants.map((participant) => {
      demographics[participant.id] = participant.demographic_ids
        .map((id) => {
          return state.catalog.demographics[id];
        })
        .filter((demographic) => demographic !== undefined);
    });
    return demographics;
  },
  /**
   * Returns all entries, unfiltered. Used to get accurate counts for the Conversations page
   * @param state StoreState
   */
  getAllEntries: (state: StoreState): Entry[] => {
    return Object.values(state.catalog.entries);
  },
  getDisplayMode: (state: StoreState): DisplayMode => {
    return state.catalog.displayMode;
  },
  getLoadingMode: (state: StoreState): LoadingMode => {
    return state.catalog.loadingMode;
  },
  getError: (state: StoreState): Error | undefined => {
    return state.catalog.error;
  },
  getInternalCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'internal'
      )
    );
  },
  getAllCodes: (state: StoreState): Code[] => {
    return sortCodes(Object.values(state.catalog.codes));
  },
  getFiltersQuery: (
    state: StoreState,
    filters: Filter[],
    filterJoin: FilterJoin
  ): string => {
    const allCodes = sortCodes(Object.values(state.catalog.codes));
    const demographics = sortCodes(Object.values(state.catalog.demographics));

    const parentedFilters = allCodes.length
      ? cloneDeep(filters).map((filter) => {
          if (
            [FilterName.AI_SUGGESTED_CODES, FilterName.APPLIED_CODES].includes(
              filter.filter_name
            )
          ) {
            // update values for parentage
            const valuesWithParentage = Array.from(
              new Set(
                filter.values.flatMap((v) =>
                  allCodes
                    ?.filter((c) => c.parentage?.includes(v as number))
                    .map((c) => c.id)
                )
              )
            );

            return { ...filter, values: valuesWithParentage };
          }
          if ([FilterName.SPEAKER_DEMOGRAPHICS].includes(filter.filter_name)) {
            // update values for parentage
            const valuesWithParentage = new Set(
              filter.values.flatMap((v) =>
                demographics
                  ?.filter((c) => c.parentage?.includes(v as number))
                  .map((c) => c.id)
              )
            );
            return { ...filter, values: Array.from(valuesWithParentage) };
          }
          return filter;
        })
      : cloneDeep(filters);

    // Update filter operation to backend readable operation
    parentedFilters.map((filter) => {
      if (filter.filter_name === FilterName.EXCLUDED_FROM_PORTAL) {
        filter.operation = FilterOperation.IS_EXACT;
      }
      return filter;
    });

    return parentedFilters
      ? parentedFilters
          .filter(
            (filter) =>
              filter.values.length ||
              valuelessOperations.includes(filter.operation)
          ) // remove filters that don't have a value if value is required
          .map((c) => `filters=${JSON.stringify(c)}`)
          .join('&') + `&filter_join=${filterJoin.toString().toUpperCase()}`
      : '';
  },
  getParticipants: (state: StoreState): Participant[] => {
    return Object.values(state.catalog.participants);
  },
  getParticipantsForConversation: (
    state: StoreState,
    conversation: Conversation
  ): Participant[] => {
    return Object.values(state.catalog.participants).filter((participant) => {
      return participant.conversation_id === conversation.id;
    });
  },
  getStructuralCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'structural'
      )
    );
  },
  getThematicCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'thematic'
      )
    );
  },
  getSelectedConversation: (state: StoreState): Conversation | undefined => {
    return state.catalog.conversations[
      `${state.catalog.selectedConversationId}`
    ];
  },
  getSelectedEntry: (state: StoreState): Entry | undefined => {
    return state.catalog.entries[`${state.catalog.selectedEntryId}`];
  },
  getSelectedParticipant: (state: StoreState): Participant | undefined => {
    return state.catalog.participants[`${state.catalog.selectedParticipantId}`];
  },
  isLoading: (state: StoreState): boolean => {
    return state.catalog.isLoading;
  },
  getHighlightSnippets: (
    state: StoreState,
    snippetIds: string[]
  ): Snippet[] => {
    const allSnippets = state.catalog.snippets;
    if (Object.keys(allSnippets).length === 0) {
      // Return with an empty list
      return [];
    }
    return snippetIds
      .map((id) => {
        const snippet = allSnippets[id];
        if (snippet) {
          return snippet;
        } else {
          return { id: -1 } as Snippet;
        }
      })
      .filter((s) => s.id !== -1);
  },
  getFilters: (state: StoreState): CatalogFilter[] => {
    return state.catalog.filters;
  },
  iAmAdminOrOwner: (state: StoreState): boolean =>
    !!state.auth.user.roles.filter(
      (role) =>
        role.organization_id === state.catalog.catalog?.organization_id &&
        (role.role_type.toLowerCase() ===
          OrganizationRole.admin.toLowerCase() ||
          role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase())
    ).length,
  getSensemakers: (state: StoreState): User[] => state.catalog.sensemakers,
  getSelectedFilters: (state: StoreState): Filter[] => {
    return state.catalog.selectedFilters;
  },
  getInitialFilter: (state: StoreState): Filter => {
    return state.catalog.initialFilter;
  },
  getFilterJoin: (state: StoreState): FilterJoin => {
    return state.catalog.filterJoin;
  },
  getUnassignedCoding: (state: StoreState): number | undefined => {
    return state.catalog.unassignedCoding;
  },
};

export default selectors;
