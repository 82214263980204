import { RefObject, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, TypographyProps } from '@mui/material';

import color from 'src/assets/_util.scss';
import { VStack } from 'src/components/core/Stack';
import { View, ViewProps } from 'src/components/core/View';
import { useResizeListener } from 'src/util/useResizeListener';

export function ContextMenu({
  open,
  setOpen,
  anchorRef,
  children,
  items,
  ...props
}: ContextMenuProps) {
  const [position, setPosition] = useState(InitialPosition);

  const updatePosition = () => {
    const anchorElement = anchorRef.current;
    if (!anchorElement) return;

    // If using within a modal, ensure that the modal container has position:"relative"
    const modal = anchorElement.closest('.MuiDialog-container');
    let offset = 0;
    if (modal) {
      const { top: modalTop } = modal.getBoundingClientRect();
      offset = -modalTop;
    }

    const { top, left, height } = anchorElement.getBoundingClientRect();
    setPosition({
      left,
      top: top + height + MenuPaddingTop + offset,
    });
  };

  useResizeListener(updatePosition, null, [open]);

  return (
    <View sx={s.root(open)}>
      <View sx={s.backdrop} onClick={() => setOpen(false)} />

      <VStack {...props} sx={s.menu(position)}>
        {items
          ? items.map((props) => <MenuItem key={props.label} {...props} />)
          : children}
      </VStack>
    </View>
  );
}

/** pass the output of this hook to `ContextMenu`:
 * ```tsx
 * <ContextMenu {...outputOfHook} />
 * ```
 */
export function useContextMenu<T extends MenuItem>(
  items: T[],
  onSelectItem: (item: T) => void
) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);

  return {
    items: items.map((item) => ({
      ...item,
      onClick: () => {
        onSelectItem(item);
        setOpen(false);
      },
    })),

    anchorRef,
    open,
    setOpen,
  };
}

// STYLE

const s = {
  root: (open: boolean) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1400,
    display: open ? 'block' : 'none',
  }),
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  menu: (position: Position) => ({
    background: color.white,
    borderRadius: '12px',
    border: `1px solid ${color.gray200}`,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: 1401,
    boxShadow: '1px 1px 3px rgba(0,0,0,.12)',
    ...position,
  }),
};

// DEFINITIONS

const InitialPosition = { left: 0, top: 0 };
const MenuPaddingTop = 4;

type Position = {
  top: number;
  left: number;
};

export type ContextMenuProps = ViewProps & {
  open: boolean;
  setOpen: (open: boolean) => void;
  anchorRef: RefObject<HTMLElement>;
  items?: MenuItemProps[];
};

// ••••••••••••••••••••••••••••••••••••••••••••••••
// MENU ITEM

export function MenuItem({ label, children, sx, ...props }: MenuItemProps) {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body1"
      sx={{
        cursor: 'pointer',
        padding: `${ItemHPadding}px 16px`,
        color: color.foraPurpleDarker,
        ':hover': {
          backgroundColor: color.gray200,
        },
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        justifyContent: 'space-between',
        width: '100%',
        ...sx,
      }}
      role="button"
      {...props}
    >
      {t(label)}
      {children}
    </Typography>
  );
}

// DEFINITIONS

const ItemHPadding = 12;

export type MenuItemProps = TypographyProps & MenuItem;

export type MenuItem = {
  label: string; // translation key or string
};
